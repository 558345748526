import React, { useState } from 'react'
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import LinearProgress from '@material-ui/core/LinearProgress'

const RelatedForm = () => {
  const [successDialog, setSuccessDialog] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [targetSku, setTargetSku] = useState('')
  const [relatedSkus, setRelatedSkus] = useState('')

  const handleNewInput = (e) => {
    if (e.target.id === 'targetSku') {
      setTargetSku(e.target.value)
    } else if (e.target.id === 'relatedSkus') {
      setRelatedSkus(e.target.value)
    }
  }

  const handleClose = () => {
    setSuccessDialog(false)
  }

  const handleAssociateSku = async () => {
    if (targetSku.length > 0 && relatedSkus.length > 0) {
      setSubmitting(true)
      try {
        const response = await axios.post('/api/magento/related', {
          // Replace with your actual API endpoint
          sku: targetSku,
          related: relatedSkus.split(',').map((sku) => sku.trim()), // Assuming comma-separated
          overwrite: false,
        })

        if (response.status === 200) {
          setSubmitting(false)
          setSuccessDialog(true)
          setTargetSku('')
          setRelatedSkus('')
          console.log('Products associated successfully:', response.data)
        } else {
          setSubmitting(false)
          console.error(
            'Error associating products:',
            response.status,
            response.data
          )
        }
      } catch (error) {
        setSubmitting(false)
        console.error('Error associating products:', error)
      }
    }
  }

  return (
    <div>
      {/* association response modal */}
      <Dialog open={successDialog} onClose={handleClose}>
        <DialogTitle>Success!</DialogTitle>
        <DialogContent>
          <DialogContentText>Products associated.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginTop: 15 }}
            onClick={handleClose}
            variant='contained'
            color='secondary'>
            yeah alright
          </Button>
        </DialogActions>
      </Dialog>

      <form style={{ padding: 25 }} noValidate autoComplete='off'>
        <TextField
          value={targetSku}
          onChange={handleNewInput}
          id='targetSku'
          label='Target SKU'
          InputLabelProps={{ shrink: true }}
          placeholder='Single SKU'
          width='200px'
          margin='normal'
          style={{ margin: '0 100px 15px 0', width: 200 }}
        />
        <TextField
          value={relatedSkus}
          onChange={handleNewInput}
          id='relatedSkus'
          label='Related SKUs'
          InputLabelProps={{ shrink: true }}
          placeholder='Comma-separated list of related SKUs'
          margin='normal'
          style={{ margin: '0 100px 15px 0', width: 400 }}
        />
        <Button
          onClick={handleAssociateSku}
          variant='contained'
          disabled={submitting}
          style={{ marginRight: 15, background: '#f26322', display: 'flex' }}>
          Associate
          <MergeTypeIcon style={{ marginLeft: 10 }} />
        </Button>
        {submitting && (
          <LinearProgress
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
            color='secondary'
          />
        )}
      </form>
    </div>
  )
}

export default RelatedForm
