import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchWarrantyProducts,
  fetchReturnOrders,
  createReturnOrder,
  removeFromWarrantyWH,
  updateWarrantyEmail,
  updateStatusHistory,
  createWarrantyPO,
  createWarrantyBstock,
  removeBrandFromWarrantyUI,
  sendEmailToVendor
} from '../actions'
import PaperPage from './PaperPage'
import TopBar from './TopBar'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ExternalLink from './ExternalLink'
import WarrantyIntakeTable from './WarrantyIntakeTable'
import WarrantyStatusTable from './WarrantyStatusTable'


class Warranty extends Component {

  state = {
    loading: true,
    submitting: false,
    responseModal: false,
    errorModal: false,
    errorMessage: undefined,
    noneSelected: false,
    selectedBrandId: undefined,
    warrantyEmail: undefined,
    warrantyEmailChanged: false,
    selectedProducts: undefined, // array of products, with index as id
    lastReturnOrder: undefined,
    emailStatus:  'READY', // READY, SENDING, SENT, FAILED
    emailError: '',
  }

  componentDidMount() {
    this.props.fetchWarrantyProducts().then(() => {
      this.setState({
        loading: false,
      })
    })
  }

  handleSelectBrand = (_event, newValue) => {
    if (!newValue) {
      return
    }
    let brandId = newValue.value
    let brand = this.findBrand(brandId)
    let products = []
    brand.products.forEach((product) => {
      product.notes.forEach((note) => {
        products.push({
          select: true,
          sku: product.sku,
          bpName: product.bpName,
          bpIdBstock: product.bpIdBstock,
          bpProductId: product.bpId,
          parentId: product.parentId,
          parentName: product.parentName,
          parentSku: product.parentSku,
          locationId: product.locationId,
          productQuantity: product.quantity,
          customerNote: note.customerNote,
          internalNote: note.internalNote,
          goodsNoteId: note.goodsNoteId,
          serialNumber: note.serialNumber,
          quantity: note.quantity,
          enteredOn: note.updatedOn,
          salesCreditId: note.orderId,
          salesOrderId: note.parentOrderId,
          salesOrderDate: note.parentOrderPlacedOn,
          createdBy: note.createdBy,
        })
      })
    })
    // if brand.warrantyEmail is null use brand.vendorEmail
    const warrantyEmail = brand.warrantyEmail || brand.vendorEmail
    this.setState({
      selectedBrandId: newValue.value,
      selectedProducts: products,
      noneSelected: false,
      warrantyEmail: warrantyEmail,
      warrantyEmailChanged: false
    })
  }

  handleSelectProduct = (index, event) => {
    let newSelectedProducts = this.state.selectedProducts
    newSelectedProducts[index].select = event.target.checked
    let noneSelected = true
    for (const i in newSelectedProducts) {
      if (newSelectedProducts[i].select == true) {
        noneSelected = false
      }
    }
    this.setState({
      selectedProducts: newSelectedProducts,
      noneSelected: noneSelected
    })
  }

  handleChangeEmail = (event) => {
    this.setState({
      warrantyEmailChanged: true,
      warrantyEmail: event.target.value
    })
  }

  handleChangeNote = (index, event) => {
    let newSelectedProducts = this.state.selectedProducts
    newSelectedProducts[index].internalNote = event.target.value

    this.setState({
      selectedProducts: newSelectedProducts
    })
  }

  handleChangeSerial = (index, event) => {
    let newSelectedProducts = this.state.selectedProducts
    newSelectedProducts[index].serialNumber = event.target.value

    this.setState({
      selectedProducts: newSelectedProducts
    })
  }

  handleCloseModal = (_event) => {

    this.props.removeBrandFromWarrantyUI(this.state.selectedBrandId)
    this.props.fetchReturnOrders()
    this.setState({
      responseModal: false,
      submitting: false,
      noneSelected: false,
      selectedBrandId: undefined,
      warrantyEmail: undefined,
      warrantyEmailChanged: false,
      selectedProducts: undefined, // array of products, with index as id
      lastReturnOrder: undefined,
    })
  }


  createMailtoLink = (brand, products, warrantyEmail) => {
    const toAddr = warrantyEmail
    let body = `Hello!\n\n` + `I'm reaching out today with a list of` +
      ` defective items returned by our customers and confirmed to be faulty` +
      ` by our staff. Below you'll find a list of the items along with` +
      ` their associated issues.`
    let body2 = `\n\nPlease advise on how you'd like to proceed` +
      ` with replacements, credits, or repairs for these units. \n\nLet me know` +
      ` if you have any further questions or need any further details - I'm` +
      ` happy to assist!\n\n\nThanks,\nBryce`
    let date = new Date()
    let slashDate = Intl.DateTimeFormat('en-US',
      { day: "2-digit", month: "2-digit", year: "2-digit" }
    ).format(date)
    let subject = encodeURIComponent(
      `${brand.brandName} Warranty Request ${slashDate}`
    )

    let productList = "\n\n\n"

    let listNumber = 1
    products.forEach((product) => {
      const issue = product.internalNote || ""
      productList += `${listNumber}. ${product.bpName}, ` +
        `Serial Num: ${product.serialNumber || ""}, ` +
        `Quantity: ${product.quantity}\n    ` +
        `- Issue: ${issue.replace(/(\r\n|\n|\r)/gm, " ")}\n`
      listNumber++
    })

    body = body + productList + body2
    let bodyURI = encodeURIComponent(body)
    let mailto = `mailto:${toAddr}?subject=${subject}&body=`
    const mailtoAndBody = mailto + bodyURI
    if (mailtoAndBody.length > 1900) {
      navigator.clipboard.writeText(body)
      window.open(mailto, '_blank')
    }
    else {
      window.open(mailtoAndBody, '_blank')
    }
  }


  handleSendEmail = async (brand, products, warrantyEmail) => {
    let body = {}
    body['brandName'] = brand
    body['products'] = products
    body['warrantyEmail'] = warrantyEmail
    await this.props.sendEmailToVendor(body)
  }

  handleCreateRO = (_event) => {
    this.setState({ submitting: true })
    let brand = this.findBrand(this.state.selectedBrandId)
    let products = []           // product list for Return Order
    let rows = {}               // product rows for Purchase Order
    let newBstockProducts = {}
    const initialStatus = { id: 95, name: 'Warranty - Email Sent' }

    // First create B-STOCK product entries in brightpearl for products
    // that don't already have one
    this.state.selectedProducts.forEach((product) => {
      if (!product.select)
        return
      if (!product.bpIdBstock) {
        let sku = product.parentSku || product.sku
        newBstockProducts[sku] = {
          bpName: product.parentName || product.bpName,
          brandId: brand.bpBrandId
        }
      }
    })
    this.props.createWarrantyBstock(newBstockProducts).then(() => {
      // Second create a Purchase Order & Purchase Credit for all
      // selected warranty items for this brand

      // Add selected products to a list of orders to add to the purchase order
      const newBstockProductIds = this.props.warranty.response
      let selectedProducts = this.state.selectedProducts

      // Iterate through selected products to prepare data for PO & RO creation
      selectedProducts.forEach((product) => {
        if (!product.select)
          return
        // Return order tracks products by item
        products.push(product)
        if (!product.bpIdBstock) {
          // Fill in product ids for newly created B-STOCK products in selectedProducts
          product.bpIdBstock = newBstockProductIds[product.parentSku || product.sku]
        }
        const productId = product.bpProductId
        // The order rows track items by sku
        if (!rows[productId]) {
          rows[productId] = {
            name: product.bpName,
            bpIdBstock: product.bpIdBstock,
            quantity: product.quantity,
            parentId: product.parentId
          }
        } else {
          rows[productId].quantity++
        }
      })
      // Create Purchase Order with above order list
      return this.props.createWarrantyPO({
        bpVendorId: brand.bpVendorId,
        initialStatusId: initialStatus.id,
        rows: rows
      })
    }).then(() => {
      // Third create a Return Order in mongodb to track the status
      // of this Purchase Order in mongodb
      const returnOrder = {
        bpBrandId: brand.bpBrandId,
        bpVendorId: brand.bpVendorId,
        poId: this.props.warranty.response.poId,
        pcId: this.props.warranty.response.pcId,
        products: products,
        statusHistory: [
          {
            from: undefined,
            to: initialStatus,
            date: new Date()
          }
        ]
      }
      this.setState({
        lastReturnOrder: returnOrder
      })
      // Once the PO is created add an entry to the warranties collection
      return this.props.createReturnOrder(returnOrder)
    }).then(() => {
      // Fourth post a Stock Correction to brightpearl to remove the selected
      // items from the warranty warehouse
      const stock = {} // Use a dictionary to collate the stock corrections together
      this.state.selectedProducts.forEach((product) => {

        if (product.select) {
          const poId = this.props.warranty.response.poId
          if (!stock[product.bpProductId]) {
            stock[product.bpProductId] = {
              productId: product.bpProductId,
              quantity: -(product.quantity),
              locationId: product.locationId,
              reason: `Created purchase order: ${poId} for this item`
            }
          } else {
            stock[product.bpProductId].quantity -= product.quantity
          }
          if (-(stock[product.bpProductId].quantity) > product.productQuantity) {
            stock[product.bpProductId].quantity = -product.productQuantity
          }
        }
      })
      let stockCorrections = []
      for (const productId in stock) {
        stockCorrections.push({
          productId: productId,
          quantity: stock[productId].quantity,
          locationId: stock[productId].locationId,
          reason: stock[productId].reason
        })
      }
      if (this.state.warrantyEmailChanged) {
        this.props.updateWarrantyEmail({
          bpVendorId: brand.bpVendorId,
          warrantyEmail: this.state.warrantyEmail
        })
      }
      return this.props.removeFromWarrantyWH({ corrections: stockCorrections })
    }).then(() => {
      // Finally update the UI to communicate that the Return Order,
      // Purchase Order, Purchase Credit and Stock Correction have been posted
      this.setState({
        submitting: false,
        responseModal: true,
      })
      this.props.fetchReturnOrders()
    }).catch((error) => {
      console.error(error.response.data)
      this.setState({
        submitting: false,
        errorModal: true,
        errorMessage: error.response.data
      })

    })
  }
  /**
   * Find Brand in global state using brandId
   * @param {Number} brandId - the brand Id
   */
  findBrand = (brandId) => {
    return this.props.warranty.brands.find((brand) => brand._id == brandId)
  }
  /**
   * Get a '<vendor> | <brand>' string if they are different
   * @param {object} brand - the selected brand object
   */
  getVendorBrand = (brand) => {
    let vendorBrand = brand.vendorName
    if (brand.vendorName != brand.brandName) {
      vendorBrand = `${brand.vendorName} | ${brand.brandName}`
    }
    return vendorBrand
  }

  render() {
    // Create Brand Options based on table data
    let brandOptions, brandLabel
    if (this.state.loading) {
      brandOptions = [{ value: 0, title: "...Loading" }]
      brandLabel = "...Loading"
    } else {
      brandOptions = this.props.warranty.brands.map((brand) => {
        if (!brand)
          return
        let vendorBrand = this.getVendorBrand(brand)
        return { value: brand._id, title: vendorBrand, count: brand.numItems }
      })
      brandLabel = "Vendor | Brand"
    }

    let productsTable, submitButton, responseModal, errorModal
    if (!this.state.selectedBrandId) {
      productsTable = <span></span>
      submitButton = <span></span>
    } else {
      let thisBrand = this.findBrand(this.state.selectedBrandId)
      let products = []
      thisBrand.products.forEach((product) =>
        product.notes.forEach((note) => products.push(note))
      )

      submitButton = (
        <Button
          size='large'
          style={{ // marginLeft: 15, marginRight: 15,
            minWidth: 140,
            background: '#34b4c9',
          }}
          onClick={this.handleCreateRO}
          disabled={this.state.submitting || this.state.noneSelected}
        >
          Create
          <AddIcon style={{ marginLeft: 10 }} />
        </Button>
      )
      const brand = this.findBrand(this.state.selectedBrandId)
      const headingColor = "rgba(0, 0, 0, 0.54)"
      productsTable = (
        <div style={{ padding: 15 }}>
          <Grid container spacing={4} alignItems='flex-end'>
            <Grid item>
              <Typography style={{ color: headingColor }} variant="body1">
                vendor
              </Typography>
              <Typography style={{ color: headingColor }} variant="h6">
                {brand.vendorName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: headingColor }} variant="body1">
                brand
              </Typography>
              <Typography style={{ color: headingColor }} variant="h6">
                {brand.brandName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label='email'
                fullWidth={true}
                value={this.state.warrantyEmail}
                onChange={this.handleChangeEmail}
              />
            </Grid>
          </Grid>
          <WarrantyIntakeTable
            brandName={brand.brandName}
            products={this.state.selectedProducts}
            onSelect={this.handleSelectProduct}
            onChangeNote={this.handleChangeNote}
            onChangeSerial={this.handleChangeSerial}
          />
        </div>
      )
      const lastRO = this.state.lastReturnOrder
      const bpPOUrl = "https://use1.brightpearlapp.com/patt-op.php?scode=invoice&oID="
      const bpPOId = !lastRO ? undefined : lastRO.poId
      const bpPOLink = !bpPOId ? "" : bpPOUrl + bpPOId
      const bpPCId = !lastRO ? undefined : lastRO.pcId
      const bpPCLink = !bpPOId ? "" : bpPOUrl + bpPCId
      let responseModalButtons = () => {
        let emailStatus = this.state.emailStatus
        if (emailStatus == 'READY' || emailStatus == 'SENDING') {
          return (
            <>
              <Button
                disabled={this.state.emailStatus == 'SENDING'}
                variant="contained"
                fullWidth={true}
                onClick={this.handleCloseModal}
                size="small"
              >Close</Button>
              <Button
                disabled={this.state.emailStatus == 'SENDING'}
                onClick={() => {
                  this.setState({
                    emailStatus: 'SENDING'
                  })
                  this.handleSendEmail(
                    brand.brandName,
                    this.state.selectedProducts.filter(
                      (product) => product.select == true),
                    this.state.warrantyEmail
                  ).then(() => {
                    this.setState({
                      emailStatus: 'SENT'
                    })
                    }).catch((error) => {
                      this.setState({
                        emailStatus: 'FAILED',
                        emailError: error.response.data
                      })
                    })
                }}
                variant="contained"
                fullWidth={true}
                size="small"
                style={{ background: '#34b4c9' }}
              >
                {this.state.emailStatus == 'SENDING' ?
                  'Sending...' : 'Send Email'}
              </Button>
            </>
          )
        } else if (emailStatus == 'SENT' || emailStatus == 'FAILED') {
          return (
            <Button
              variant="contained"
              fullWidth={true}
              onClick={() => {
                this.handleCloseModal()
                this.setState({
                  emailStatus: 'READY'
                })
              }}
              size="small"
            >OK</Button>
          )
        }
      }
      let responseModalEmailText = () => {
        if (this.state.emailStatus == 'SENT') {
          return (
            <DialogContentText>
              {'Email to '}
              <Typography display='inline' variant='h6'>
                {brand.vendorName+' | '+brand.brandName}
              </Typography>{' sent'}

            </DialogContentText>
          )
        } else if (this.state.emailStatus == 'FAILED'){
          return (
            <>
              <DialogContentText>
                {'Failed to send email to: '}
                <Typography display='inline' variant='h6'>
                  {this.state.warrantyEmail}
                </Typography>
              </DialogContentText>
              <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                {this.state.emailError}
              </DialogContentText>
            </>
          )
        }
      }
      responseModal = (
        <Dialog open={this.state.responseModal}>
          <DialogTitle>
            Return Order Created
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Purchase Order:
              <ExternalLink
                href={bpPOLink}
                content={bpPOId}
              />
            </DialogContentText>
            <DialogContentText>
              Purchase Credit:
              <ExternalLink
                href={bpPCLink}
                content={bpPCId}
              />
            </DialogContentText>
            {responseModalEmailText()}
          </DialogContent>
          <DialogActions>
            {responseModalButtons()}
          </DialogActions>
          {(this.state.emailStatus == 'SENDING') && (<LinearProgress />)}
        </Dialog>
      )
      errorModal = (
        <Dialog open={ this.state.errorModal }>
          <DialogTitle>
            Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ whiteSpace: 'pre-line' }}>
              {this.state.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({ errorModal: false }) }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
    return (
      <PaperPage lg={9} maxWidth="100%">
        <TopBar pageName='Warranty Tracking' />
        <Typography
          style={{ padding: 10 }}
          variant='subtitle1'>Create Return Order</Typography>
        <Grid container spacing={2} alignItems='center' style={{ padding: 10 }}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              fullWidth={true}
              /* style={{ minWidth: 200, marginRight: 15, marginBottom: 15 }} */>
              <Autocomplete
                id="brand-vendor-select"
                onChange={this.handleSelectBrand}
                options={brandOptions}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.value == value.value}
                renderInput={(params) =>
                  <TextField {...params}
                    label={brandLabel}
                    variant="outlined" />
                }
                renderOption={(option) =>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      {option.title}
                    </Grid>
                    <Grid item container xs={2} justifyContent='flex-end'>
                      {option.count}
                    </Grid>
                  </Grid>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            {submitButton}
            {this.state.submitting && (<LinearProgress />)}
          </Grid>
        </Grid>
        {productsTable}
        {responseModal}
        {errorModal}
        <Typography
          style={{ padding: 15 }}
          variant='subtitle1'>Active Return Orders</Typography>
        <WarrantyStatusTable
          handleSendEmail={this.handleSendEmail}
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ warranty }) {
  return { warranty };
}

const mapDispatchToProps = {
  fetchWarrantyProducts,
  fetchReturnOrders,
  createReturnOrder,
  removeFromWarrantyWH,
  updateWarrantyEmail,
  updateStatusHistory,
  createWarrantyPO,
  createWarrantyBstock,
  removeBrandFromWarrantyUI,
  sendEmailToVendor
};

export default connect(mapStateToProps, mapDispatchToProps)(Warranty);
