import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/**
 * Re-usable dropdown menu!
 * 
 * @typedef {object} DropdownProps
 * @property {string} id identifier
 * @property {string} label label
 * @property {Array} entries options in the dropdown
 * @property {Function} onChangeHandler handler for onChange function
 * 
 * @param {DropdownProps} props 
 * @returns {React component}
 */
const Dropdown = ({ id, label, entries, onChangeHandler }) => {

  return (
    <FormControl fullWidth={true} >
      <Autocomplete
        id={id}
        renderInput={(params) =>
          <TextField {...params}
            label={label}
            variant="outlined" />
          }
        onChange={onChangeHandler}
        options={entries}
        getOptionLabel={(option) => option.title}
        getOptionSelected={(option, value) => option.value == value.value}
        renderOption={(option) =>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              {option.title}
            </Grid>
            <Grid item container xs={2} justifyContent='flex-end'>
              {option.count}
            </Grid>
          </Grid>
        }
      />
    </FormControl>
  )
}

export default Dropdown;