import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import ReactTable from 'react-table'
import ExternalLink from './ExternalLink'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import EmailIcon from '@material-ui/icons/Email'
import Snackbar from '@material-ui/core/Snackbar'
import TopBar from './TopBar'

const Backorders = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [expanded, setExpanded] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [vendorId, setVendorId] = useState('')

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const renderedContentRef = useRef(null)

  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const handleSubmitVendor = async (vendorId, send = false) => {
    setSubmitting(true)
    setVendorId(vendorId)
    try {
      const response = await axios.post('/api/send-reminder', {
        vendorId,
        send,
      })
      setModalContent(response.data)
      setModalOpen(true)
    } catch (error) {
      setModalContent('Error processing request: ' + error.message)
      setModalOpen(true)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCopyToClipboard = async () => {
    try {
      // Get the rendered HTML from the ref
      const renderedHtml = renderedContentRef.current.outerHTML
      // Create clipboard item with text/html MIME type
      const blob = new Blob([renderedHtml], { type: 'text/html' })
      // eslint-disable-next-line no-undef
      const clipboardItem = new ClipboardItem({ 'text/html': blob })

      await navigator.clipboard.write([clipboardItem])
      setSnackbarMessage('Rendered HTML copied to clipboard')
      setSnackbarOpen(true)
    } catch (error) {
      console.log(error)
      setSnackbarMessage('Failed to copy to clipboard')
      setSnackbarOpen(true)
    }
  }

  const handleSendEmail = async () => {
    try {
      await axios.post('/api/sendEmail', { htmlContent: modalContent })
      setSnackbarMessage('Email sent successfully')
      setSnackbarOpen(true)
      setModalOpen(false)
    } catch (error) {
      setSnackbarMessage('Failed to send email')
      setSnackbarOpen(true)
    }
  }

  let columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 140,
      style: { textAlign: 'left' },
      Footer: (info) => 'Total:',
      Cell: (row) => {
        if (row.value) {
          let url = `https://use1.brightpearlapp.com/report.php?output-old=screen&report_type=purchases&report_submit=1&contact_id=0&invoice_ref=&contact_name_id=&contact_company_id=&sortby=o.date_purchased&sort_dir=DESC&search=&date_type=date_purchased&multiselect_date_type=date_purchased&date_timeframe=custom&multiselect_date_timeframe=custom&date_from=11%2F01%2F2016&date_to=12%2F01%2F2030&campaign_id=&multiselect_campaign_id=&project_id=&multiselect_project_id=&order_type=2&multiselect_order_type=2&price_list=&multiselect_price_list=&invoiced=0&multiselect_invoiced=0&country_id=&multiselect_country_id=&createdBy=0&multiselect_createdBy=0&owner_id=0&multiselect_owner_id=0&contact_group_id=&multiselect_contact_group_id=&warehouse_id=&multiselect_warehouse_id=&suppliers_id=${row.original.bpId}&multiselect_suppliers_id=${row.original.bpId}&drop_ship=0&multiselect_drop_ship=0&stock_status%5B%5D=4&stock_status%5B%5D=5&multiselect_stock_status=4&multiselect_stock_status=5&preset-name=&submit2=Filter&preset-columns=orders_id%2Ccustomers_id%2Cdate_purchased%2Corders_invoice_num%2Corders_customer_ref%2Corders_status_name%2Corder_stock_status_id%2Corder_amount%2Camount_paid%2Corders_parent%2Corder_payment_status_id%2Corders_project%2CPCF_NOTES%2Corders_campaign_id%2Cactions&limit=50&results_from=1&results_to=3&output=screen`

          return <ExternalLink href={url} content={row.value} />
        }
      },
    },
    {
      Header: 'Paid Orders',
      accessor: 'paidOrderCount',
      width: 150,
      Footer: (info) => {
        return info.data.reduce((sum, row) => row.paidOrderCount + sum, 0)
      },
    },
    {
      Header: 'Paid Liability',
      accessor: 'paidLiability',
      width: 150,
      Cell: (row) => {
        if (row.value) return usdFormatter.format(row.value)
      },
      Footer: (info) => {
        let total = info.data.reduce((sum, row) => row.paidLiability + sum, 0)
        return usdFormatter.format(total)
      },
    },
    {
      Header: 'Open Orders',
      accessor: 'openOrderCount',
      width: 150,
      Footer: (info) => {
        return info.data.reduce((sum, row) => row.openOrderCount + sum, 0)
      },
    },
    {
      Header: 'Unpaid Liability',
      accessor: 'unpaidLiability',
      width: 150,
      Cell: (row) => {
        if (row.value) return usdFormatter.format(row.value)
      },
      Footer: (info) => {
        let total = info.data.reduce((sum, row) => row.unpaidLiability + sum, 0)
        return usdFormatter.format(total)
      },
    },
    {
      Header: 'Lead Time',
      accessor: 'leadTime',
      width: 150,
    },
    {
      Header: 'Max Age',
      accessor: 'maxAge',
      width: 150,
    },
    {
      Header: 'Reminded',
      accessor: 'lastBackorderReminder',
      width: 150,
      Cell: (row) => (row.value ? row.value.split('T')[0] : null),
    },
    {
      expander: true,
      Header: 'Backorders',
      width: 100,
      Expander: ({ isExpanded, row }) => {
        if (row._original.orders.length > 0) {
          return (
            <div style={{ display: 'inline-flex', paddingTop: 6 }}>
              ({row._original.orders.length})
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
          )
        }
      },
      getProps: (state, rowInfo, column) => {
        return {
          className: 'show-pointer',
        }
      },
      style: {
        padding: '0',
        textAlign: 'right',
        userSelect: 'none',
      },
    },
    {
      Header: 'Action',
      width: 100,
      accessor: 'orders',

      Cell: (row) => {
        if (row.value.length > 0) {
          return (
            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={() => handleSubmitVendor(row.original.bpId)}
              disabled={submitting}>
              Submit
            </Button>
          )
        }
      },
    },
  ]

  let orderSubcolumns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 120,
      style: { textAlign: 'left' },
      Cell: (row) => {
        if (row.value) {
          let url = `https://use1.brightpearlapp.com/patt-op.php?scode=invoice&action=edit&oID=${row.value}`

          return <ExternalLink href={url} content={row.value} />
        }
      },
    },
    {
      Header: 'Origin ID',
      accessor: 'originId',
      width: 120,
      style: { textAlign: 'left' },

      Cell: (row) => {
        if (row.value && row.value != row.original.id) {
          let url = `https://use1.brightpearlapp.com/patt-op.php?scode=invoice&action=edit&oID=${row.value}`

          return <ExternalLink href={url} content={row.value} />
        }
      },
    },
    {
      Header: 'Ref',
      accessor: 'reference',
      width: 250,
      style: { textAlign: 'left' },
    },
    {
      Header: '# Prod',
      accessor: 'orderRows',
      width: 140,
      Cell: (row) => {
        if (row.value) {
          return row.value.length
        }
      },
    },
    {
      Header: 'Age',
      accessor: 'aging',
      width: 140,
    },
  ]

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get('/api/backorders')
        const liabilityData = response.data
        // Set initial state
        setData(liabilityData)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <Paper style={{ padding: 20, margin: 20 }}>
      <TopBar pageName='Backorders' />

      <ReactTable
        data={data}
        columns={columns}
        style={{ textAlign: 'right', border: 0, height: 1000 }}
        showPagination
        filterable
        loading={loading}
        loadingText='Loading...'
        defaultSorted={[{ id: 'paidLiability', desc: true }]}
        defaultFilterMethod={(filter, row) => {
          let rowValue = row[filter.id] == undefined ? '' : row[filter.id]
          return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
        }}
        expanded={expanded}
        onExpandedChange={(newExpanded) => setExpanded(newExpanded)}
        SubComponent={(row) =>
          row.original.orders.length > 0 ? (
            <ReactTable
              data={row.original.orders}
              columns={orderSubcolumns}
              style={{ height: 300 }}
              className='-striped -highlight'
            />
          ) : null
        }
        minRows={10}
        pageSize={100}
        className='-striped -highlight'
      />

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby='vendor-response-modal'>
        <Paper
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            maxHeight: '90vh',
            padding: 20,
            overflow: 'auto',
          }}>
          <div
            ref={renderedContentRef}
            dangerouslySetInnerHTML={{ __html: modalContent }}
            style={{ marginBottom: 20 }}
          />

          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item>
              <Button
                variant='contained'
                onClick={handleCopyToClipboard}
                startIcon={<FileCopyIcon />}>
                Copy
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleSubmitVendor(vendorId, true)}
                startIcon={<EmailIcon />}>
                Send Email
              </Button>
            </Grid>
            <Grid item>
              <Button variant='outlined' onClick={() => setModalOpen(false)}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />

      {submitting && (
        <LinearProgress
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          }}
          color='secondary'
        />
      )}
    </Paper>
  )
}

export default Backorders
