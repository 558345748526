import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import moment from 'moment'
import LockIcon from '@material-ui/icons/Lock'
import ReactTooltip from 'react-tooltip'
import ReactTable from 'react-table'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const WarehouseLineChart = () => {
  const [data, setData] = useState([])
  const [staleData, setStaleData] = useState(0)
  const [brandData, setBrandData] = useState([])
  const [overstockData, setOverstockData] = useState(0)

  const [loading, setLoading] = useState(true)

  const [filteredData, setFilteredData] = useState([])
  const [warehouseIds, setWarehouseIds] = useState([])
  const [currentValue, setCurrentValue] = useState(0)
  const [yoyGrowth, setYoyGrowth] = useState(0)
  const [momGrowth, setMomGrowth] = useState(0)

  const [selectedWarehouseId, setSelectedWarehouseId] = useState(2)

  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const getPrevGrowth = (data, range) => {
    let todayData = data.pop()
    const prevYearDate = moment(todayData.date).subtract(1, range)

    // Find the entry for the same date in the previous year
    //accounts for when there isnt an exact yoy date
    const prevYearEntry = data.findLast((e) => {
      let dateDiff = moment(e.date).diff(prevYearDate, 'days')
      if (dateDiff <= 0) return e
    })
    if (prevYearEntry) {
      let growth = todayData.value / prevYearEntry.value - 1
      growth = Math.round(growth * 1000) / 10
      return growth
    } else return '-'
  }

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/stock-levels')
        const stockData = response.data
        setData(stockData)

        const warehouses = await axios.get('/api/warehouses')
        setWarehouseIds(warehouses.data)

        // Extract unique warehouse IDs
        let defaultWarehouse = stockData.filter(
          (item) => item.warehouseId === 2
        )
        setFilteredData(defaultWarehouse)
        setCurrentValue(defaultWarehouse.pop().value)

        let yoyGrowth = getPrevGrowth(defaultWarehouse, 'year')
        setYoyGrowth(yoyGrowth)
        let momGrowth = getPrevGrowth(defaultWarehouse, 'month')
        setMomGrowth(momGrowth)

        const overstock = await axios.get('/api/reporting/overstock')
        setOverstockData(overstock.data.total)

        const stale = await axios.get('/api/reporting/stale-inventory')
        let staleTotal = stale.data
          .filter((val) => val.staleValue != undefined)
          .reduce((acc, cur) => acc + cur.staleValue, 0)
        setBrandData(stale.data)
        setStaleData(staleTotal)

        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  //re-render
  const handleWarehouseChange = (e) => {
    const warehouseId = Number(e.target.value)
    setSelectedWarehouseId(warehouseId)

    // Filter data by selected warehouse ID
    let filterWarehouse = data
    if (warehouseId) {
      filterWarehouse = data.filter((item) => item.warehouseId === warehouseId)
    }

    setFilteredData(filterWarehouse)
    setCurrentValue(filterWarehouse.pop().value)

    let yoyGrowth = getPrevGrowth(filterWarehouse, 'year')
    setYoyGrowth(yoyGrowth)
    let momGrowth = getPrevGrowth(filterWarehouse, 'month')
    setMomGrowth(momGrowth)
  }

  return (
    <div style={{ margin: '0 0 20px 24px' }}>
      <FormControl style={{ marginTop: 16, marginBottom: 8, width: 150 }}>
        <InputLabel>Warehouse</InputLabel>
        <Select
          value={selectedWarehouseId}
          onChange={(e) => handleWarehouseChange(e)}>
          {warehouseIds.map((wh) => (
            <MenuItem key={wh.id} value={wh.id}>
              {wh.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <h2>Inventory Value</h2>
      <div
        style={{
          margin: '0 0 24px 0',
          width: '100%',
          display: 'flex',
          gap: 20,
          textAlign: 'center',
        }}>
        <div
          style={{
            padding: '10px 20px',
            border: '1px solid #c7c7c7',
            borderRadius: 5,
            width: 250,
          }}>
          <p
            style={{
              color: 'black',
              margin: '0 0 8px 0',
            }}>
            Current Value
          </p>
          <h2 style={{ margin: '0 0 8px 0' }}>
            {usdFormatter.format(currentValue)}
          </h2>
          <p style={{ margin: 0 }}>
            {yoyGrowth > 0 ? '▲' : '▼'} {yoyGrowth}% YoY |{' '}
            {momGrowth > 0 ? '▲' : '▼'} {momGrowth}% MoM
          </p>
        </div>
        <ReactTooltip id='locked' place='top' type='dark' effect='solid'>
          This metric is not affected by filters
        </ReactTooltip>
        <div
          style={{
            padding: '10px 20px',
            border: '1px solid #c7c7c7',
            borderRadius: 5,
            width: 250,
            position: 'relative',
          }}>
          <LockIcon
            data-tip
            data-for='locked'
            style={{ position: 'absolute', top: 5, left: 5, color: '#adadad' }}
          />
          <p
            style={{
              color: 'black',
              margin: '0 0 8px 0',
            }}>
            Stale Stock
          </p>
          <h2 style={{ margin: '0 0 8px 0' }}>
            {loading ? '-' : usdFormatter.format(staleData)}
          </h2>
          <p style={{ margin: 0 }}>{'>'} 180 days since last sale</p>
        </div>
        <div
          style={{
            padding: '10px 20px',
            border: '1px solid #c7c7c7',
            borderRadius: 5,
            width: 250,
            position: 'relative',
          }}>
          <LockIcon
            data-tip
            data-for='locked'
            style={{ position: 'absolute', top: 5, left: 5, color: '#adadad' }}
          />
          <p
            style={{
              color: 'black',
              margin: '0 0 8px 0',
            }}>
            Overstock
          </p>
          <h2 style={{ margin: '0 0 8px 0' }}>
            {loading ? '-' : usdFormatter.format(overstockData)}
          </h2>
          <p style={{ margin: 0 }}>{'>'} 2 months stock</p>
        </div>
      </div>

      <ResponsiveContainer width='95%' height={400}>
        <LineChart
          data={filteredData}
          margin={{ top: 20, right: 30, left: 50, bottom: 5 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='date'
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
          />
          <YAxis
            tickFormatter={(value) => usdFormatter.format(value)} // Format Y-axis values as USD
          />
          <Tooltip
            formatter={(value, name) => {
              if (name === 'Value') {
                return usdFormatter.format(value) // Format as USD
              }
              return value // Return other values as-is
            }}
            labelFormatter={(label) =>
              `Date: ${new Date(label).toLocaleDateString()}`
            }
          />
          <Legend
            formatter={(value) => {
              if (value === 'Value') {
                return 'Value (USD)' // Add USD to legend
              }
              return value
            }}
          />
          <Line
            type='monotone'
            dataKey='value'
            stroke='#8884d8'
            name='Value'
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <h2>Stale Brands</h2>
      <ReactTable
        data={brandData}
        columns={[
          {
            Header: 'Brand',
            accessor: 'name',
            style: { textAlign: 'left' },
          },
          {
            Header: 'Total Value',
            accessor: 'totalValue',
            Cell: (row) => usdFormatter.format(row.value),
          },
          {
            Header: 'Stale Stock',
            accessor: 'staleValue',
            Cell: (row) => (row.value ? usdFormatter.format(row.value) : ''),
          },
          {
            Header: 'Stale Ratio',
            accessor: 'staleRatio',
            Cell: (row) => (row.value ? Math.round(row.value * 100) + '%' : ''),
          },
          {
            Header: 'Clearance',
            accessor: 'blacklist.clearance',
            Cell: (row) => (row.value ? 'X' : ''),
            style: { textAlign: 'center' },
          },
        ]}
        defaultPageSize={50}
        style={{ textAlign: 'right', width: 1200 }}
        className='-striped -highlight'
        loading={loading}
        loadingText='Loading...'
        defaultSorted={[{ id: 'staleRatio', desc: true }]}
      />
    </div>
  )
}

export default WarehouseLineChart
