import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import TopBar from './TopBar'
import Paper from '@material-ui/core/Paper'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import ReportEmployee from './ReportEmployee'

const Dashboard = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const per = (value) => {
    return Math.round(value) + '%'
  }

  const formatDate = (id) => {
    const [year, month] = id.split('-')
    return new Date(year, month - 1).toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    })
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  const colorGrowth = (rowInfo, attrName) => {
    let attrs = attrName.split('.')
    if (!rowInfo) return {}
    const value = rowInfo.original[attrs[0]][attrs[1]]
    return {
      style: {
        color: value > 0 ? 'green' : value < 0 ? 'red' : 'black',
      },
    }
  }

  // Fetch full data set once
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get('/api/reporting/shop')
        console.log('API Response:', response.data) // ✅ Debugging API data
        setData(response.data)
      } catch (err) {
        console.error('API Error:', err)
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  // Table columns
  const columns = [
    { Header: 'Date', accessor: '_id', width: 130 },
    {
      Header: 'In Store',
      headerStyle: { fontWeight: 'bold', color: '#34b4c9' },

      columns: [
        {
          Header: 'Revenue',
          accessor: 'inStoreRevenue',
          width: 130,
          Cell: (row) => formatter.format(row.value),
          getProps: () => ({
            style: {
              borderLeft: '1px solid gray',
            },
          }),
        },
        {
          Header: 'MoM',
          accessor: 'momGrowth.inStoreRevenue',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'momGrowth.inStoreRevenue'),
        },
        {
          Header: 'YoY',
          accessor: 'yoyGrowth.inStoreRevenue',
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'yoyGrowth.inStoreRevenue'),
          width: 50,
          Cell: (row) => per(row.value),
        },

        { Header: 'Orders', accessor: 'inStoreOrders', width: 70 },
        {
          Header: 'MoM',
          accessor: 'momGrowth.inStoreOrders',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'momGrowth.inStoreOrders'),
        },
        {
          Header: 'YoY',
          accessor: 'yoyGrowth.inStoreOrders',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'yoyGrowth.inStoreOrders'),
        },
      ],
    },

    {
      Header: 'Pickup',
      headerStyle: { fontWeight: 'bold', color: '#eb5202' },

      columns: [
        {
          Header: 'Revenue',
          accessor: 'pickupRevenue',
          width: 130,
          Cell: (row) => formatter.format(row.value),
          getProps: () => ({
            style: {
              borderLeft: '1px solid gray',
            },
          }),
        },
        {
          Header: 'MoM',
          accessor: 'momGrowth.pickupRevenue',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'momGrowth.pickupRevenue'),
        },
        {
          Header: 'YoY',
          accessor: 'yoyGrowth.pickupRevenue',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'yoyGrowth.pickupRevenue'),
        },

        { Header: 'Orders', accessor: 'pickupOrders', width: 70 },
        {
          Header: 'MoM',
          accessor: 'momGrowth.pickupOrders',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'momGrowth.pickupOrders'),
        },
        {
          Header: 'YoY',
          accessor: 'yoyGrowth.pickupOrders',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'yoyGrowth.pickupOrders'),
        },
      ],
    },

    {
      Header: 'Total',
      headerStyle: { fontWeight: 'bold', color: 'green' },
      columns: [
        {
          Header: 'Revenue',
          accessor: 'totalRevenue',
          width: 130,
          Cell: (row) => formatter.format(row.value),
          getProps: () => ({
            style: {
              borderLeft: '1px solid gray',
            },
          }),
        },
        {
          Header: 'MoM',
          accessor: 'momGrowth.totalRevenue',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'momGrowth.totalRevenue'),
        },
        {
          Header: 'YoY',
          accessor: 'yoyGrowth.totalRevenue',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'yoyGrowth.totalRevenue'),
        },

        { Header: 'Orders', accessor: 'totalOrders', width: 50 },
        {
          Header: 'MoM',
          accessor: 'momGrowth.totalOrders',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'momGrowth.totalOrders'),
        },
        {
          Header: 'YoY',
          accessor: 'yoyGrowth.totalOrders',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'yoyGrowth.totalOrders'),
        },

        { Header: 'Foot Traffic', accessor: 'footTrafficCount', width: 130 },
        {
          Header: 'MoM',
          accessor: 'momGrowth.footTrafficCount',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'momGrowth.footTrafficCount'),
        },
        {
          Header: 'YoY',
          accessor: 'yoyGrowth.footTrafficCount',
          width: 50,
          Cell: (row) => per(row.value),
          getProps: (state, rowInfo) =>
            colorGrowth(rowInfo, 'yoyGrowth.footTrafficCount'),
        },

        {
          Header: 'Conversion',
          accessor: 'conversionRate',
          Cell: (row) => per(row.value),
          width: 100,
        },
        // {
        //   Header: 'MoM',
        //   accessor: 'momGrowth.conversionRate',
        //   width: 50,
        //   Cell: (row) => per(row.value),
        //   getProps: (state, rowInfo) =>
        //     colorGrowth(rowInfo, 'momGrowth.conversionRate'),
        // },
        // {
        //   Header: 'YoY',
        //   accessor: 'yoyGrowth.conversionRate',
        //   width: 50,
        //   Cell: (row) => per(row.value),
        //   getProps: (state, rowInfo) =>
        //     colorGrowth(rowInfo, 'yoyGrowth.conversionRate'),
        // },
      ],
    },
  ]

  return (
    <div style={{ padding: '0 24px  20px 24px' }}>
      <h2>Shop Performance</h2>
      <ResponsiveContainer width='95%' height={400}>
        <LineChart
          data={data || []}
          margin={{ top: 20, right: 30, left: 50, bottom: 40 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='_id'
            tickFormatter={(id) => (id ? formatDate(id) : '')}
          />
          <YAxis tickFormatter={formatter.format} />
          <Tooltip formatter={formatter.format} />
          <Legend />

          {/* Ensure data keys exist before mapping */}

          <Line
            type='monotone'
            dataKey='totalRevenue'
            stroke='#1bbf58'
            strokeWidth={2}
            name='Total Revenue'
          />
          <Line
            type='monotone'
            dataKey='pickupRevenue'
            stroke='#eb5202'
            strokeWidth={2}
            name='Pickup Revenue'
          />
          <Line
            type='monotone'
            dataKey='inStoreRevenue'
            stroke='#34b4c9'
            strokeWidth={2}
            name='In-Store Revenue'
          />
        </LineChart>
      </ResponsiveContainer>

      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={18}
        style={{ textAlign: 'right', width: 1700 }}
        className='-striped -highlight'
        loading={loading}
        loadingText='Loading...'
        defaultSorted={[{ id: '_id', desc: true }]}
      />
      <ReportEmployee />
    </div>
  )
}

export default Dashboard
