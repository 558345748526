import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import TopBar from './TopBar'
import Paper from '@material-ui/core/Paper'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const ReportEmployee = () => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])

  const [loading, setLoading] = useState(true)

  const per = (value) => {
    return Math.round(value) + '%'
  }

  const formatDate = (id) => {
    const [year, month] = id.split('-')
    return new Date(year, month - 1).toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    })
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  const colorGrowth = (rowInfo, attrName) => {
    let attrs = attrName.split('.')
    if (!rowInfo) return {}
    const value = rowInfo.original[attrs[0]][attrs[1]]
    return {
      style: {
        color: value > 0 ? 'green' : value < 0 ? 'red' : 'black',
      },
    }
  }

  // Fetch full data set once
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get('/api/reporting/employee-performance')
        console.log('API Response:', response.data) // ✅ Debugging API data

        let resData = response.data

        const employeesSet = new Set()
        resData.forEach((entry) => {
          entry.employees.forEach((emp) => {
            employeesSet.add(emp.name)
          })
        })
        const employeeColumns = Array.from(employeesSet).map((name) => ({
          Header: name,
          columns: [
            {
              Header: 'Hours',
              accessor: `${name}.totalHoursWorked`,
              Cell: (row) => (row.value ? row.value.toFixed(2) : null),

              getProps: () => ({
                style: {
                  borderLeft: '1px solid gray',
                },
              }),
            },
            { Header: 'Sales', accessor: `${name}.totalSales` },
            {
              Header: 'Revenue',
              accessor: `${name}.totalRevenue`,
              Cell: (row) => (row.value ? formatter.format(row.value) : null),
            },
            {
              Header: 'SPH',
              accessor: `${name}.revPerHour`,
              Cell: (row) => (row.value ? formatter.format(row.value) : null),
            },
            {
              Header: 'AOV',
              accessor: `${name}.avgSaleValue`,
              Cell: (row) => (row.value ? formatter.format(row.value) : null),
            },
          ],
        }))

        const rows = resData.map((entry) => {
          const row = { month: entry._id }
          entry.employees.forEach((emp) => {
            row[emp.name] = {
              totalHoursWorked: emp.totalHoursWorked,
              totalSales: emp.totalSales,
              totalRevenue: emp.totalRevenue,
              revPerHour: emp.revPerHour,
              avgSaleValue: emp.avgSaleValue,
            }
          })
          return row
        })

        setData(rows)
        setColumns([{ Header: 'Month', accessor: 'month' }, ...employeeColumns])
      } catch (err) {
        console.error('API Error:', err)
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <div style={{ marginTop: 40, marginBottom: 40 }}>
      <h2>Employee Performance</h2>

      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={20}
        style={{ textAlign: 'right' }}
        className='-striped -highlight'
        loading={loading}
        loadingText='Loading...'
        defaultSorted={[{ id: 'month', desc: true }]}
      />
    </div>
  )
}

export default ReportEmployee
