import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchArrivals } from '../actions'
import { format } from 'date-fns'
import ReactTable from 'react-table'
// import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Button from '@material-ui/core/Button'

class Arrivals extends Component {
  state = {
    tabs: 0,
    loading: true,
  }
  componentDidMount() {
    this.props.fetchArrivals().then((resp) => {
      this.setState({ loading: false })
    })
  }

  render() {
    return (
      <div>
        <ReactTable
          data={this.props.arrivals}
          columns={[
            { Header: 'SKU', accessor: 'bp.sku', width: 200 },
            { Header: 'Name', accessor: 'bp.bpName' },
            {
              Header: 'Quantity',
              accessor: 'bp.bpOnHand',
              width: 85,
              style: { textAlign: 'center' },
            },
            {
              Header: 'Price',
              accessor: 'm2.price',
              width: 85,
              style: { textAlign: 'center' },
              filterMethod: (filter, row) => {
                return row[filter.id] > filter.value
              },
            },
            { Header: 'Badge', accessor: 'm2.badges', width: 85 },

            {
              Header: 'Arrival Date',
              accessor: 'updatedOn',
              width: 200,
              Cell: (row) => format(row.value, 'MMM D h:mm A'),
            },
          ]}
          filterable
          style={{ textAlign: 'left', border: 0 }}
          defaultSorted={[{ id: 'updatedOn', desc: true }]}
          showPagination
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
        />
      </div>
    )
  }
}

function mapStateToProps({ arrivals }) {
  return { arrivals }
}
const mapDispatchToProps = {
  fetchArrivals,
}
export default connect(mapStateToProps, mapDispatchToProps)(Arrivals)
