import React, { useCallback } from 'react';
import { 
  Editor,
  EditorProvider,
  Toolbar,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnUnderline,
  HtmlButton,
  Separator,
  createButton
} from 'react-simple-wysiwyg';

const TextEditorBox = (props) => {
  const { 
    attribute,    // attribute being displayed
    content,      // the text to be edited (likely a product description)
    product,      // the entire product data
    handleChange, // handler for changing product data
    // handlePaste   // handler for pasting in text (html sanitizer, etc)
    } = props;
  const htmlSanitize = require('sanitize-html-react');

  const sanitized = htmlSanitize(content)

  const RevertButton = createButton('Revert Text', '↶', () => {
    const originalText = `${attribute}_og`
    handleChange(product[originalText], product);
  });
  
  return(
    <EditorProvider>
      <Editor
        style={{
          textWrap: 'wrap',
        }}
        containerProps={{
          style: {
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
          }
        }}
        value={content}
        onChange={(e) => handleChange(e.target.value, product)}
      >
        <Toolbar>
          <HtmlButton />
          <Separator />
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <Separator />
          <BtnNumberedList />
          <BtnBulletList />
          <Separator />
          <BtnLink />
          <BtnClearFormatting />
          <Separator />
          <RevertButton />
        </Toolbar>
      </Editor>
    </EditorProvider>
  )
}

export default TextEditorBox;