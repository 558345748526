import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchRelated, fetchRelatedSuggestions } from '../actions'
import { tabQuery } from './custom-js'

import ReactTable from 'react-table'
import TopBar from './TopBar'
import TopBarRow from './TopBarRow'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import PaperPage from './PaperPage'
import RelatedForm from './RelatedForm'
import RelatedBulkImport from './RelatedBulkImport'

import BoughtTogether from './BoughtTogether'
import ReactTooltip from 'react-tooltip'

class Related extends Component {
  state = {
    suggLoading: true,
    liveLoading: true,
    tabs: tabQuery(this),
  }

  tabber = (event, value) => {
    this.setState({ tabs: value })
  }
  per = (value) => {
    return Math.floor(value * 100) + '%'
  }
  componentDidMount() {
    this.props.fetchRelatedSuggestions().then((resp) => {
      this.setState({ suggLoading: false })
      this.props.fetchRelated().then((resp) => {
        this.setState({ liveLoading: false })
      })
    })
  }

  relatedColumns = [
    {
      Header: 'SKU',
      accessor: 'mainSku',
      width: 100,
    },
    {
      Header: 'Name',
      accessor: 'mainName',
      width: 400,
    },
    {
      Header: 'Status',
      accessor: 'mainStatus',
      width: 75,
    },
    { Header: 'Related SKU', accessor: 'relatedSku', width: 100 },
    { Header: 'Related Name', accessor: 'relatedName', width: 400 },
    { Header: 'Related Status', accessor: 'relatedStatus', width: 200 },
  ]

  suggestedColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 100,
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 550,
    },

    { Header: 'Related SKU', accessor: 'relatedSku', width: 100 },
    { Header: 'Related Name', accessor: 'relatedName', width: 550 },
    {
      Header: 'Attach Rate',
      accessor: 'attachRate',
      width: 100,
      Cell: (row) => {
        if (row.value > 0) {
          return this.per(row.value)
        } else {
          return
        }
      },
    },
  ]

  render() {
    return (
      <PaperPage>
        <TopBar pageName='Related Products'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/magento/related-suggestions/csv'}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export Suggestions
            </Button>
            <Button
              variant='contained'
              href={'/api/magento/related/csv'}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export Live
            </Button>
          </TopBarRow>
        </TopBar>
        <RelatedForm />
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab data-tip data-for='suggest' label='Suggestions' />
            <Tab data-tip data-for='live' label='Live' />
            <Tab data-tip data-for='lookup' label='Lookup' />
            <Tab data-tip data-for='bulk' label='Bulk Upload' />
          </Tabs>
        </AppBar>

        <ReactTooltip id='suggest' place='top' type='dark' effect='solid'>
          Suggested related products
        </ReactTooltip>
        <ReactTooltip id='live' place='top' type='dark' effect='solid'>
          Currently live relationships
        </ReactTooltip>
        <ReactTooltip id='lookup' place='top' type='dark' effect='solid'>
          Manual suggestion lookup by sku
        </ReactTooltip>
        <ReactTooltip id='bulk' place='top' type='dark' effect='solid'>
          Bulk CSV Import
        </ReactTooltip>

        {this.state.tabs === 0 && (
          <ReactTable
            data={this.props.suggested}
            columns={this.suggestedColumns}
            style={{ textAlign: 'left', border: 0, height: '750px' }}
            defaultSorted={[{ id: 'attachRate', desc: true }]}
            showPagination
            filterable
            defaultFilterMethod={(filter, row) =>
              row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >
              -1
            }
            pageSize={100}
            className='-striped -highlight'
            loading={this.state.suggLoading}
            loadingText='Loading...'
          />
        )}

        {this.state.tabs === 1 && (
          <ReactTable
            data={this.props.related}
            columns={this.relatedColumns}
            style={{ textAlign: 'left', border: 0, height: '750px' }}
            defaultSorted={[
              { id: 'relatedStatus', desc: false },
              { id: 'mainName', desc: false },
              { id: 'relatedName', desc: false },
            ]}
            showPagination
            filterable
            defaultFilterMethod={(filter, row) =>
              row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >
              -1
            }
            pageSize={100}
            className='-striped -highlight'
            loading={this.state.liveLoading}
            loadingText='Loading...'
          />
        )}

        {this.state.tabs === 2 && <BoughtTogether />}
        {this.state.tabs === 3 && <RelatedBulkImport />}
      </PaperPage>
    )
  }
}

function mapStateToProps({ related, suggested }) {
  return { related, suggested }
}
const mapDispatchToProps = {
  fetchRelated,
  fetchRelatedSuggestions,
}
export default connect(mapStateToProps, mapDispatchToProps)(Related)
