import React, { useState } from 'react'
import CSVDragAndDrop from './CSVDragAndDrop'

function ProductImportPage() {
  return (
    <div style={{ padding: '0 24px 24px' }}>
      <h2>Import Products from CSV</h2>
      <p style={{ color: 'grey' }}>
        Accepts a CSV with 'sku' column and 'related' column of comma-sep skus
      </p>
      <CSVDragAndDrop uploadEndpoint={'/api/magento/related'} />
    </div>
  )
}

export default ProductImportPage
