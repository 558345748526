import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, OutlinedInput } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const inputStyle = makeStyles((_theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  buttonPlus: {
    backgroundColor: 'limegreen',
    '&:hover': {
      backgroundColor: 'lightgreen'
    },
  },
  buttonMinus: {
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'indianred'
    },
    '&:active': {
      boxShadow: 'none',
    }
  }
}))

const NumberInputMobile = (props) => {
  const classes = inputStyle()

  const {
    onChange, value: propValue, defaultValue, maxInputWidth, ...propsRest
  } = props
  const isControlled = typeof propValue != 'undefined'
  const hasDefaultValue =  typeof defaultValue != 'undefined'
  const initialInternalValue =  hasDefaultValue ? defaultValue : ''

  const [internalValue, setInternalValue] = useState(initialInternalValue)

  const value = isControlled ? propValue : internalValue

  return (
    <div className={classes.root}>
      <Button
        onClick={(event) => {
          let newValue
          if (value === '' || value === '-') {
            newValue = -1
          } else {
            newValue = parseInt(value) - 1
          }
          if (!isControlled) {
            setInternalValue(newValue)
          }
          event.target.value = newValue
          onChange(event)
        }}
        className={classes.buttonMinus}
        variant="contained"
        size="large"
        style={{ minWidth: "50px", maxWidth: "50px" }}>
        <RemoveIcon />
      </Button>
      <OutlinedInput
        margin="dense"
        value={value}
        style={{ minWidth: "50px", maxWidth: maxInputWidth }}
        onChange={(event) => {
          let input = event.target.value
          let newValue
          if (input === '-') {
            if (!isControlled) {
              setInternalValue(input)
            }
            return
          } else if (input === '') {
            newValue = input
          } else if (!isNaN(parseInt(input))) {
            newValue = parseInt(input)
          } else {
            return
          }
          if (!isControlled) {
            setInternalValue(newValue)
          }
          event.target.value = newValue
          onChange(event)
        }}
        {...propsRest}
      />
      <Button
        onClick={(event) => {
          let newValue
          if (value === '' || value === '-') {
            newValue = 1
          } else {
            newValue = parseInt(value) + 1
          }
          if (!isControlled) {
            setInternalValue(newValue)
          }
          event.target.value = newValue
          onChange(event)
        }}
        className={classes.buttonPlus}
        variant="contained"
        size="large"
        style={{ minWidth: "50px", maxWidth: "50px" }}>
        <AddIcon />
      </Button>
    </div>
  )
}
export default NumberInputMobile
