import React, { useState, useEffect } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'

import ReactTooltip from 'react-tooltip'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

const ReportRevenue = () => {
  const [data, setData] = useState([])
  const [customerData, setCustomerData] = useState({})

  const [aggData, setAggData] = useState([])
  const [summaryData, setSummaryData] = useState({})
  const [channels, setChannels] = useState([])
  const [loading, setLoading] = useState(true)

  const [revColor, setRevColor] = useState('green')
  const [orderColor, setOrderColor] = useState('green')
  const [basketColor, setBasketColor] = useState('green')
  const [dailyColor, setDailyColor] = useState('green')
  const [customerColor, setCustomerColor] = useState('green')

  const [selectedChannel, setSelectedChannel] = useState('all')
  const [summaryType, setSummaryType] = useState('day')
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'))
  const [endDate, setEndDate] = useState(moment())

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  //initial fetch
  useEffect(() => {
    console.log(startDate.toISOString())
    fetch(
      `/api/reporting/revenue?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    ) // Replace with actual API
      .then((res) => res.json())
      .then((orders) => {
        setData(orders)
        aggregateData(orders)
        summarizeData(orders)
      })
  }, [])

  //refresh fetch
  useEffect(() => {
    console.log(selectedChannel)
    fetch(
      `/api/reporting/revenue?startDate=${startDate}&endDate=${endDate}&channel=${selectedChannel}`
    ) // Replace with actual API
      .then((res) => res.json())
      .then((orders) => {
        setData(orders)
        aggregateData(orders)
        summarizeData(orders)
      })
  }, [selectedChannel, startDate, endDate])

  useEffect(() => {
    fetch(`/api/brightpearl/channels`) // Replace with actual API
      .then((res) => res.json())
      .then((channels) => {
        console.log(channels)
        channels = channels.filter((ch) => ch.enabled)
        setChannels(channels)
      })
  }, [])

  //get customer info
  useEffect(() => {
    fetch(
      `/api/reporting/customers?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    ) // Replace with actual API
      .then((res) => res.json())
      .then((customerData) => {
        setCustomerData(customerData)
        if (customerData.firstPurchaseChange < 0) {
          setCustomerColor('red')
        }
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    setLoading(true)

    fetch(
      `/api/reporting/customers?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&channel=${selectedChannel}`
    )
      .then((res) => res.json())
      .then((customerData) => {
        if (customerData.firstPurchaseChange < 0) {
          setCustomerColor('red')
        }
        setCustomerData(customerData)
        setLoading(false)
      })
  }, [selectedChannel, startDate, endDate])

  //date pivot select
  useEffect(() => {
    aggregateData(data)
  }, [summaryType])

  const summarizeData = (orders) => {
    let summary = {}
    summary.totalRev = 0
    summary.totalOrders = 0
    summary.prevRev = 0
    summary.prevOrders = 0

    orders.forEach((order) => {
      summary.totalRev += order.totalRevenue
      summary.totalOrders += order.totalOrders
      summary.prevRev += order.prevYearRevenue
      summary.prevOrders += order.prevYearOrders
    })

    let numDays = endDate.diff(startDate, 'days')
    summary.basket = summary.totalRev / summary.totalOrders
    summary.prevBasket = summary.prevRev / summary.prevOrders
    summary.currentDaily = summary.totalRev / numDays
    summary.prevDaily = summary.prevRev / numDays

    //calculate raw growth totals
    summary.basketGrowth = summary.basket / summary.prevBasket - 1
    summary.revGrowth = summary.totalRev / summary.prevRev - 1
    summary.orderGrowth = summary.totalOrders / summary.prevOrders - 1
    summary.dailyGrowth = summary.currentDaily / summary.prevDaily - 1

    //clean up growth percentages
    summary.basketGrowth = Math.round(summary.basketGrowth * 1000) / 10
    summary.revGrowth = Math.round(summary.revGrowth * 1000) / 10
    summary.orderGrowth = Math.round(summary.orderGrowth * 1000) / 10
    summary.dailyGrowth = Math.round(summary.dailyGrowth * 1000) / 10

    //cast to dollar string
    summary.basket = formatter.format(summary.basket)
    summary.totalRev = formatter.format(summary.totalRev)
    summary.totalOrders = summary.totalOrders.toLocaleString()
    summary.currentDaily = formatter.format(summary.currentDaily)

    setRevColor('green')
    setOrderColor('green')
    setBasketColor('green')
    setDailyColor('green')

    if (summary.revGrowth < 0) {
      setRevColor('red')
    }
    if (summary.orderGrowth < 0) {
      setOrderColor('red')
    }
    if (summary.basketGrowth < 0) {
      setBasketColor('red')
    }
    if (summary.dailyGrowth < 0) {
      setDailyColor('red')
    }
    setSummaryData(summary)
  }

  const aggregateData = (orders) => {
    const grouped = {}
    orders.forEach((entry) => {
      let key = moment.utc(entry.date).format('YYYY-MM-DD')
      console.log(entry.date)
      console.log(key)
      if (summaryType === 'week')
        key = `W${moment(entry.date).week()}-${moment(entry.date).year()}`
      if (summaryType === 'month') key = moment(entry.date).format('YYYY-MM')

      if (!grouped[key])
        grouped[key] = {
          ...entry,
          date: key,
          totalOrders: 0,
          totalRevenue: 0,
          prevYearRevenue: 0,
          prevYearOrders: 0,
        }
      grouped[key].totalOrders += entry.totalOrders
      grouped[key].totalRevenue += entry.totalRevenue
      grouped[key].prevYearOrders += entry.prevYearOrders
      grouped[key].prevYearRevenue += entry.prevYearRevenue
    })

    let aggregated = Object.values(grouped)
    setAggData(aggregated)
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className='p-4'>
        {/* Filters */}
        <div className='flex gap-4 mb-4'>
          <FormControl style={{ marginTop: 16, marginLeft: 24, width: 150 }}>
            <InputLabel>Channel</InputLabel>
            <Select
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}>
              <MenuItem value='all'>All</MenuItem>
              {channels.map((ch) => (
                <MenuItem key={ch.id} value={ch.id}>
                  {ch.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ marginTop: 16, marginLeft: 24, width: 100 }}>
            <InputLabel>Grouping</InputLabel>
            <Select
              value={summaryType}
              onChange={(e) => setSummaryType(e.target.value)}>
              <MenuItem value='day'>Day</MenuItem>
              <MenuItem value='week'>Week</MenuItem>
              <MenuItem value='month'>Month</MenuItem>
            </Select>
          </FormControl>

          {/* Date Pickers */}
          <KeyboardDatePicker
            style={{ marginTop: 16, marginLeft: 24, width: 150 }}
            margin='normal'
            label='Start Date'
            format='YYYY-MM-DD'
            value={startDate}
            onChange={(date) => setStartDate(moment(date))}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
          />

          <KeyboardDatePicker
            style={{ marginTop: 16, marginLeft: 24, width: 150 }}
            margin='normal'
            label='End Date'
            format='YYYY-MM-DD'
            value={endDate}
            onChange={(date) => setEndDate(moment(date))}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
          />
        </div>
        <h2 style={{ marginLeft: 24 }} className='text-xl mb-2'>
          {selectedChannel === 'all'
            ? 'Total Revenue (All Channels)'
            : `Revenue for ${
                channels.find((ch) => ch.id == selectedChannel).label
              }`}
        </h2>
        {/* summary container */}
        <div
          style={{
            margin: '0 0 24px 24px',
            width: '100%',
            display: 'flex',
            gap: 20,
            textAlign: 'center',
          }}>
          <div
            style={{
              color: revColor,
              padding: '10px 20px',
              border: '1px solid #c7c7c7',
              borderRadius: 5,
              width: 175,
            }}>
            <p
              style={{
                color: 'black',
                margin: '0 0 8px 0',
              }}>
              Revenue
            </p>
            <h2 style={{ margin: '0 0 8px 0' }}>{summaryData.totalRev}</h2>
            <p style={{ margin: 0 }}>
              {summaryData.revGrowth > 0 ? '▲' : '▼'} {summaryData.revGrowth}%
            </p>
          </div>

          <div
            style={{
              color: orderColor,
              padding: '10px 20px',
              border: '1px solid #c7c7c7',
              borderRadius: 5,
              width: 175,
            }}>
            <p
              style={{
                color: 'black',
                margin: '0 0 8px 0',
              }}>
              Orders
            </p>
            <h2 style={{ margin: '0 0 8px 0' }}>{summaryData.totalOrders}</h2>
            <p style={{ margin: 0 }}>
              {summaryData.orderGrowth > 0 ? '▲' : '▼'}{' '}
              {summaryData.orderGrowth}%
            </p>
          </div>
          <div
            style={{
              color: basketColor,
              padding: '10px 20px',
              border: '1px solid #c7c7c7',
              borderRadius: 5,
              width: 175,
            }}>
            <p
              style={{
                color: 'black',
                margin: '0 0 8px 0',
              }}>
              AOV
            </p>
            <h2 style={{ margin: '0 0 8px 0' }}>{summaryData.basket}</h2>
            <p style={{ margin: 0 }}>
              {summaryData.basketGrowth > 0 ? '▲' : '▼'}{' '}
              {summaryData.basketGrowth}%
            </p>
          </div>
          <div
            style={{
              color: dailyColor,
              padding: '10px 20px',
              border: '1px solid #c7c7c7',
              borderRadius: 5,
              width: 175,
            }}>
            <p
              style={{
                color: 'black',
                margin: '0 0 8px 0',
              }}>
              Avg. Daily Revenue
            </p>
            <h2 style={{ margin: '0 0 8px 0' }}>{summaryData.currentDaily}</h2>
            <p style={{ margin: 0 }}>
              {summaryData.dailyGrowth > 0 ? '▲' : '▼'}{' '}
              {summaryData.dailyGrowth}%
            </p>
          </div>
          <div
            style={{
              color: customerColor,
              padding: '10px 20px',
              border: '1px solid #c7c7c7',
              borderRadius: 5,
              width: 175,
            }}>
            <p
              style={{
                color: 'black',
                margin: '0 0 8px 0',
              }}>
              New Customers
            </p>
            <h2 style={{ margin: '0 0 8px 0' }}>
              {loading ? '...' : customerData.firstPurchase + '%'}
            </h2>
            <p style={{ margin: 0 }}>
              {loading ? '' : customerData.firstPurchaseChange > 0 ? '▲' : '▼'}
              {loading ? '' : customerData.firstPurchaseChange + '%'}
            </p>
          </div>
          <ReactTooltip id='locked' place='top' type='dark' effect='solid'>
            This metric is not affected by filters
          </ReactTooltip>
          <div
            style={{
              padding: '10px 20px',
              border: '1px solid #c7c7c7',
              borderRadius: 5,
              width: 175,
              position: 'relative',
            }}>
            <LockIcon
              data-tip
              data-for='locked'
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                color: '#adadad',
              }}
            />
            <p
              style={{
                margin: '0 0 8px 0',
              }}>
              CLV
            </p>
            <h2 style={{ margin: '0 0 8px 0' }}>${customerData.clv}</h2>
            <p style={{ margin: 0 }}>-</p>
          </div>
        </div>

        {/* Chart Container */}

        <ResponsiveContainer width='95%' height={300}>
          <LineChart
            data={aggData}
            margin={{ top: 20, right: 30, left: 50, bottom: 5 }}>
            <XAxis dataKey='date' />
            <YAxis tickFormatter={formatter.format} />
            <Tooltip formatter={formatter.format} />
            <Legend />
            <Line
              animationDuration='500'
              type='linear'
              dataKey='totalRevenue'
              stroke='#38761d'
              name='Current Revenue'
            />
            <Line
              animationDuration='500'
              type='linear'
              dataKey='prevYearRevenue'
              stroke='#b6d7a8'
              name='YoY'
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default ReportRevenue
