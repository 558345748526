import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchM2Brands,
  fetchBrandText,
  updateTextAttributes,
} from '../actions';

import TextEditorBox from './TextEditorBox';

import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

class Text extends Component {
  state = {
    selectedBrand: '',
    brandsLoading: true,
    brandsTable: [],
    updateItems: [],
    modalOpen: false,
    submitting: false,
  }

  componentDidMount() {
    this.props.fetchM2Brands().then((resp) => {
      this.setState({ brandsLoading: false });
    }).then(() => {
      this.setState({
      });
    });
  }

  handleSelectBrand = (_event, newValue) => {
    if (newValue) {
      this.setState({ selectedBrand: newValue.title });
      this.props.fetchBrandText({ name: newValue.title }).then(() => {
        this.setState({ brandsTable: this.props.pmText.brand });
      });
    } else {
      this.setState({ selectedBrand: '' })
    }
  }

  handleDescEdit = (text, data) => {
    const index = data._index;
    data.description = text;
    const table = [...this.state.brandsTable];
    
    table[index] = data;

    this.setState({ brandsTable: table })
  }

  handleSpecEdit = (text, data) => {
    const index = data._index;
    data.short_description = text;
    const table = [...this.state.brandsTable];
    
    table[index] = data;

    this.setState({ brandsTable: table })
  }

  confirmSync() {
    const items = this.state.brandsTable.filter((item) => {
      return item.description !== item.description_og ||
        item.short_description !== item.short_description_og
    }).map((item) => {
      return {
        sku: item.sku,
        description: item.description,
        short_description: item.short_description,
      }
    });
    this.setState({ 
      updateItems: items,
      modalOpen: true,
     })
  }

  syncText() {
    this.setState({ 
      submitting : true,
    })
    this.props.updateTextAttributes(this.state.updateItems).then((resp) => {
      this.setState({ 
        submitting: false,
        response: resp,
      });
      this.closeModal()
      this.props.fetchBrandText({ name: this.state.selectedBrand }).then(() => {
        this.setState({ brandsTable: this.props.pmText.brand });
      });
    })
  }

  closeModal = () => {
    this.setState({ 
      modalOpen: false,
      updateItems: [],
    });
  };

  specsTemplates = {
    euro: {
      text: `<ul><li>Eurorack module</li><li>Width: <span style="color:red">XXXX</span></li><li>Depth: <span style="color:red">XXXX</span></li><li>Current draw: <span style="color:red">XXXX</span></li></ul>`
    },
    pedal: {
      text: `<ul><li>Dimensions: <span style="color:red">" x " x " / mm x mm x mm</span></li><li>Power specs: <span style="color:red">voltage polarity (included?)</span></li></ul>`
    },
    interfaces: {
      text: `<ul><li>Analog Inputs: [Number and type]</li><li>Analog Outputs: [Number and type]</li><li>Headphone Outputs: [Number and type]</li><li>Digital Inputs: [Number and type if applicable]</li><li>Digital Outputs: [Number and type if applicable]</li><li>Sample rate: Up to [Maximum rate]</li><li>Bit depth: Up to [Maximum depth]</li><li>OS requirements: [if available]</li><li>Dimensions: [if available]</li><li>Power: [if available]`
    }
  }

  copyText = (type) => {
    let text = this.specsTemplates[type].text;
    navigator.clipboard.writeText(text)
  }

  brandTextColumns = [
    { 
      Header: 'SKU',
      accessor: 'sku',
      width: 125,
      style: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      },
    },
    { 
      Header: 'Image',
      accessor: 'images',
      width: 250,
      style: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      },
      Cell: tableProps => (<img src={tableProps.row.images[0]} width={200}/>),
    },
    {
      Header: 'Description',
      accessor: 'description', 
      style: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      },
      Cell: tableProps => (
        <TextEditorBox
          attribute={'description'}
          content={tableProps.row.description}
          product={tableProps.row}
          viewState={false}
          handleChange={this.handleDescEdit}
        />
      )},
      {
        Header: 'Description OG',
        accessor: 'description_og',
        show: false,
      },
      {
        Header: 'Specs',
        accessor: 'short_description', 
        style: {
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        },
        Cell: tableProps => (
          <TextEditorBox
            attribute={'short_description'}
            content={tableProps.row.short_description}
            product={tableProps.row}
            viewState={false}
            handleChange={this.handleSpecEdit}
          />
        )},
        {
          Header: 'Specs OG',
          accessor: 'short_description_og',
          show: false,
        },
  ];

  submitColumns = [
    { 
      Header: 'SKU',
      accessor: 'sku',
      width: 125,
      style: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      },
    },
    {
      Header: 'Description',
      accessor: 'description', 
      style: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }
    },
    {
      Header: 'Specs',
      accessor: 'short_description', 
      style: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }
    }
  ]

  render() {
    // Create Brand Options based on table data
    let brandOptions;
    if (this.state.loading) {
      brandOptions = [{ value: 0, title: "...Loading" }]
    }
    else {
      const activeBrands = this.props.m2Brand.filter((brand) => {
        return brand.numProd > 0;
      });
      brandOptions = activeBrands.map((brand) => {
        return { value: brand.value, title: brand.label, count: brand.numProd }
      });
    }

     const { brandsTable } = this.state;

    return (
      <PaperPage maxWidth='100%'>

        <Modal open={this.state.modalOpen}>
            <Paper
              style={{
                position: 'absolute',
                top: `20%`,
                left: `10%`,
                width: '80%',
                aspectRatio: 1 / 2,
                outline: 0,
                padding: 25,
            }}>
              <TopBar pageName='Confirm Submission'>
                <TopBarRow justify='flex-end'>
                  This is an import—be sure to alert other PM team members before clicking submit
                  <Button
                    style={{ marginLeft: 20 }}
                    disabled={this.state.submitting} 
                    onClick={() => this.syncText()}
                    variant='contained'
                    color='secondary'>
                      Submit
                    </Button>
                    <Button
                      variant='contained'
                      disabled={this.state.submitting} 
                      onClick={() => this.closeModal()}
                      label='Close Submission Modal'
                      style={{ marginLeft: 20 }}>
                      Go Back
                    </Button>
                </TopBarRow>
              </TopBar>
              <Grid>
                <ReactTable
                  data={this.state.updateItems}
                  columns={this.submitColumns}
                  style={{ textAlign: 'left' }}
                  defaultFilterMethod={(filter, row) => {
                    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
                    return (
                      rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                    );
                  }}
                  defaultSorted={[
                    { id: 'sku', desc: false },
                  ]}
                  showPagination
                  minRows={10}
                  pageSize={100}
                  className='-striped -highlight'
                />
              </Grid>
              {this.state.submitting && (
                <LinearProgress
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                  color='secondary'
                />
              )}
            </Paper>
        </Modal>

        {/* Header Bar */}
        <TopBar pageName='Text'>
          <TopBarRow justify='flex-end'>
            Copy/Paste Specs Templates:
            <Button 
              variant='contained'
              style={{ marginLeft: 20 }}
              onClick={() => this.copyText('euro')} >
                Eurorack
            </Button>
            <Button 
              variant='contained'
              style={{ marginLeft: 20 }}
              onClick={() => this.copyText('pedal')} >
                Pedals
            </Button>
            <Button 
              variant='contained'
              style={{ marginLeft: 20 }}
              onClick={() => this.copyText('interfaces')} >
                Audio Interfaces
            </Button>
          </TopBarRow>
        </TopBar>

        <Grid container spacing={2} alignItems='center' style={{ padding: 10 }}>
          <Grid item xs={4}>
            <FormControl
              fullWidth={true}
              /*  style={{ minWidth: 200, marginRight: 15, marginBottom: 15 }} */>
              <Autocomplete
                id="brand-select"
                onChange={this.handleSelectBrand}
                options={brandOptions}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.value == value.value}
                // style={{ width: 460 }}
                renderInput={(params) =>
                  <TextField {...params}
                    label="Brand"
                    variant="outlined" />
                }
                renderOption={(option) =>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      {option.title}
                    </Grid>
                    <Grid item container xs={2} justifyContent='flex-end'>
                      {option.count}
                    </Grid>
                  </Grid>
                }
              />
            </FormControl>
          </Grid>
          <Button
            variant='contained'
            label='Sync Text Attributes'
            onClick={() => this.confirmSync()}
            style={{ marginRight: 20 }}>
            Sync Text Attributes
          </Button>
        </Grid>
        <Grid>
          <ReactTable
            data={brandsTable}
            columns={this.brandTextColumns}
            style={{ textAlign: 'left' }}
            filterable
            defaultFilterMethod={(filter, row) => {
              let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
              return (
                rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              );
            }}
            defaultSorted={[
              { id: 'pmIndex', desc: false },
            ]}
            loading={this.state.imagesLoading}
            loadingText='Loading...'
            showPagination
            minRows={10}
            pageSize={100}
            className='-striped -highlight'
            getTrProps={(state, rowInfo, column) => {
              // change row color if changing suboptimal attr
              if (
                rowInfo && (rowInfo.row.description !== rowInfo.row.description_og ||
                  rowInfo.row.short_description !== rowInfo.row.short_description_og)
              )
                return { 
                  style: { 
                    background: rowInfo.index % 2 ? '#454545' : '#676767' 
                  }
                };
              // change row color if config child
              else if (
                rowInfo &&
                rowInfo.original.visibility == 'Not Visible Individually'
              )
                return { 
                  style: { 
                    background: rowInfo.index % 2 ? '#ABABDE' : '#BCBCEF'
                  }
                };
              // change row color if config parent
              else if (
                rowInfo &&
                rowInfo.original.sku.charAt(0) == 'C'
              )
                return { 
                  style: { 
                    background: rowInfo.index % 2 ? '#9A9AEF' : '#8989DE'
                  } 
                };
              else return {};
            }}
          />
        </Grid>
      </PaperPage>
    )
  }
}

function mapStateToProps({
  pmText,
  m2Brand,
}) {
  return {
    pmText,
    m2Brand,
  };
}

const mapDispatchToProps = {
  fetchM2Brands,
  fetchBrandText,
  updateTextAttributes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Text);