import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportStockLevel from './ReportStockLevel'
import ReportRevenue from './ReportRevenue'
import ReportShop from './ReportShop'

import { tabQuery, columnQuery } from './custom-js'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TopBar from './TopBar'
import PaperPage from './PaperPage'

class Reporting extends Component {
  state = { tabs: tabQuery(this) }

  tabber = (event, value) => {
    this.setState({ tabs: value })
  }
  render() {
    return (
      <PaperPage maxWidth='100%'>
        {/* Header Bar */}
        <TopBar pageName='Reporting' />

        {/* Tabs + Tooltips */}
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab label='Revenue' />
            <Tab label='Shop' />
            <Tab label='Inventory' />
          </Tabs>
        </AppBar>
        {this.state.tabs === 0 && <ReportRevenue />}
        {this.state.tabs === 1 && <ReportShop />}
        {this.state.tabs === 2 && <ReportStockLevel />}
      </PaperPage>
    )
  }
}

export default connect()(Reporting)
