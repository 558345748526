import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Papa, { parse } from 'papaparse'
import axios from 'axios'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import Button from '@material-ui/core/Button'

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
  fontFamily: 'monospace', // Use a fixed-width font
}

const thStyles = {
  border: '1px solid #ddd',
  padding: '8px',
  backgroundColor: '#f2f2f2',
  textAlign: 'left',
}

const tdStyles = {
  border: '1px solid #ddd',
  padding: '8px',
}
const statusStyles = (status) => {
  if (status === 'error') {
    return { color: 'red', fontWeight: 'bold' }
  } else if (status === 'success') {
    return { color: 'green', fontWeight: 'bold' }
  }
  return {} // Default style for 'pending' or other statuses
}

function CSVDragAndDrop({ uploadEndpoint }) {
  const [parsedData, setParsedData] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [errorCount, setErrorCount] = useState(0)

  const [uploadStatuses, setUploadStatuses] = useState([])
  const [isUploading, setIsUploading] = useState('')
  const [isDone, setIsDone] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    setIsDone(false)
    if (file && file.type === 'text/csv') {
      setErrorMessage('')
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          //optionally filter out rows that dont have a sku
          let filteredData = results.data.filter(
            (row) => row.sku != null && row.sku.length > 0
          )
          setParsedData(filteredData)
          setUploadStatuses(
            Array(filteredData.length).fill({ status: 'pending', message: '' })
          )
        },
        error: (error) => {
          setErrorMessage(`Error parsing CSV: ${error.message}`)
          setParsedData([])
          setUploadStatuses([])
        },
      })
    } else if (file) {
      setErrorMessage('Please upload a valid CSV file.')
      setParsedData([])
      setUploadStatuses([])
    } else {
      setErrorMessage('')
      setParsedData([])
      setUploadStatuses([])
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleUpload = async () => {
    if (!parsedData || parsedData.length === 0) {
      setErrorMessage('No data to upload.')
      return
    }

    setIsUploading(true)
    const newUploadStatuses = [...uploadStatuses]

    for (let i = 0; i < parsedData.length; i++) {
      await axios
        .post(uploadEndpoint, {
          ...parsedData[i],
          overwrite: true,
        })
        .then((resp) => {
          newUploadStatuses[i] = { status: 'success' }
        })
        .catch((error) => {
          var message = error

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            message = error.response.data.error // Extract custom error message
          } else if (error.request) {
            // The request was made but no response was received
            message = error.request
          } else {
            // Something happened in setting up the request that triggered an Error
            message = error.message
          }

          newUploadStatuses[i] = { status: 'error', message: message }
          setErrorCount(errorCount + 1)
          console.error(`Error uploading row ${i + 1}:`, error)
        })

      setUploadStatuses(newUploadStatuses)
    }

    setIsUploading(false)
    setIsDone(true)
  }

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        {parsedData.length > 0 ? (
          <div>
            <CloudDoneIcon />
            <p>Ready to upload. {parsedData.length} lines found in file</p>
          </div>
        ) : (
          <div>
            <CloudUploadIcon />
            <p>Drag 'n' drop a CSV file here, or click to select file</p>
          </div>
        )}
      </div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {isUploading && (
        <p>Uploading... {errorCount > 0 ? `${errorCount} errors` : ''}</p>
      )}
      {isDone && (
        <p>Upload complete. {errorCount > 0 ? `${errorCount} errors` : ''}</p>
      )}
      {parsedData.length > 0 && (
        <div>
          <Button
            onClick={handleUpload}
            variant='contained'
            style={{ marginTop: 15, display: 'flex' }}
            disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Upload Data'}
          </Button>
          <h3>CSV Data:</h3>
          <table style={tableStyles}>
            <thead>
              <tr>
                {Object.keys(parsedData[0] || {}).map((header) => (
                  <th key={header} style={thStyles}>
                    {header}
                  </th>
                ))}
                <th style={thStyles}>Status</th>
                <th style={thStyles}>Message</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, vIndex) => (
                    <td key={vIndex} style={tdStyles}>
                      {String(value)}
                    </td>
                  ))}
                  <td
                    style={{
                      ...tdStyles,
                      ...statusStyles(uploadStatuses[index]?.status),
                    }}>
                    {uploadStatuses[index]?.status}
                  </td>
                  <td style={tdStyles}>{uploadStatuses[index]?.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

const dropzoneStyles = {
  border: '2px dashed #ccc',
  borderRadius: '16px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: '20px',
}

export default CSVDragAndDrop
