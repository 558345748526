import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  applyUsedCredit,
  fetchUsedMasterReport,
  updateUsedProduct,
} from '../actions';
import 'react-table/react-table.css';
import Paper from '@material-ui/core/Paper';

import ReactTable from 'react-table';
import Button from '@material-ui/core/Button';
import UsedModal from './UsedModal';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExternalLink from './ExternalLink';
import LinearProgress from '@material-ui/core/LinearProgress';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoIcon from '@material-ui/icons/Photo';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
class UsedTable extends Component {
  state = {
    loading: true,
    row: {},
    paymentEmail: '',
    response: {},
    submitting: false,
    responseModal: false,
    emailModal: false,
    tabs: 0,
  };

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };
  componentDidMount() {
    this.props.fetchUsedMasterReport().then((resp) => {
      this.setState({
        loading: false,
      });
    });
  }

  openEmailModal = (row) => {
    this.setState({ row: row, emailModal: true });
  };

  payOrder = (paymentType) => {
    let updateBody = {
      purchaseOrderId: this.state.row.id,
      paymentType: paymentType,
    };
    if (this.state.paymentEmail != '') {
      updateBody.paymentEmail = this.state.paymentEmail;
    }

    this.setState({ submitting: true }, () => {
      this.props.applyUsedCredit(updateBody).then(() => {
        this.setState({
          submitting: false,
          responseModal: true,
          emailModal: false,
          response: this.props.used.response,
          row: {},
        });
      });
    });
  };

  handleClose = () => {
    this.setState({
      response: {},
      row: {},
      emailModal: false,
      responseModal: false,
      paymentEmail: '',
    });
  };

  handleEmailChange = (e) => {
    this.setState({ paymentEmail: e.target.value });
    console.log(this.state);
  };

  handleUpdateProduct = (row) => {
    this.props.updateUsedProduct({
      update: !row.value,
      field: row.column.id,
      _id: row.original._id,
    });
  };

  boolCheck = (row) => {
    if (row.value) {
      return (
        <Button
          style={{
            minHeight: 0,
            minWidth: 0,
            height: '100%',
            padding: '0 20px',
          }}
          onClick={() => this.handleUpdateProduct(row)}>
          <ClearIcon style={{ fontSize: 16 }} />
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            minHeight: 0,
            minWidth: 0,
            height: '100%',
            padding: '0 20px',
          }}
          onClick={() => this.handleUpdateProduct(row)}
        />
      );
    }
  };

  searchFilter = (filter, row) => {
    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
    return (
      rowValue.toString().toLowerCase().indexOf(filter.value.toLowerCase()) > -1
    );
  };

  sortMethod = (a, b, desc) => {
    // force null and undefined to the bottom
    a = (a === null || a === undefined) ? "" : a
    b = (b === null || b === undefined) ? "" : b

    const aIsString = typeof (a) === 'string'
    const bIsString = typeof (b) === 'string'

    // force any string values to lowercase
    a = aIsString ? a.toLowerCase().trim() : a
    b = bIsString ? b.toLowerCase().trim() : b
    a = typeof a === 'string' ? a.toLowerCase() : a
    b = typeof b === 'string' ? b.toLowerCase() : b

    if (aIsString && bIsString) {
      if (!desc) {
        if (a == '' && b != '') {
          return 1
        } else if (a != '' && b == '') {
          return -1
        }
      }
    }
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
    return 0
  }

  boolFilterMethod = (filter, row) => {
    if (filter.value === 'all') {
      return true;
    }
    if (filter.value === 'yes') {
      return row[filter.id] === true;
    }
    return row[filter.id] !== true;
  };
  boolFilterDisplay = ({ filter, onChange }) => {
    return (
      <select
        onChange={(event) => {
          return onChange(event.target.value);
        }}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}>
        <option value='all'>All</option>
        <option value='yes'>Yes</option>
        <option value='no'>No</option>
      </select>
    );
  };

  orderColumns = [
    {
      Header: 'PO',
      accessor: 'id',
      width: 100,
      style: { whiteSpace: 'unset' },
      Cell: (row) => (
        <ExternalLink
          href={
            'https://use1.brightpearlapp.com/patt-op.php?scode=invoice&oID=' +
            row.value
          }
          content={row.value}
        />
      ),
    },
    {
      Header: 'Customer',
      accessor: 'customer.firstName',
      width: 250,
      Cell: (row) => {
        return (
          row.original.customer.firstName + ' ' + row.original.customer.lastName
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'po.orderStatus.name',
      width: 250,
    },
    {
      Header: 'Received',
      accessor: 'po.stockStatusCode',
      width: 80,
      style: {
        textAlign: 'center',
      },
      Cell: (row) => {
        if (row.value == 'POA') {
          return <CheckIcon style={{ fontSize: 18 }} />;
        }
        return null;
      },
    },
    {
      Header: 'Paid',
      accessor: 'po.orderPaymentStatus',
      width: 80,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Employee',
      accessor: 'submittedBy',
      width: 150,
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      width: 120,
      Cell: (row) => (row.value ? row.value.split('T')[0] : null),
    },
    {
      expander: true,
      Header: 'Products',
      width: 80,
      Expander: ({ isExpanded, row }) => {
        return (
          <div style={{ display: 'inline-flex', paddingTop: 6 }}>
            ({row._original.products.length})
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        );
      },
      getProps: (state, rowInfo, column) => {
        return {
          className: 'show-pointer',
        };
      },
      style: {
        padding: '0',
        textAlign: 'center',
        userSelect: 'none',
      },
    },
    {
      Header: 'Apply Payment',
      accessor: 'purchaseOrderId',
      filterable: false,
      style: {
        textAlign: 'center',
      },
      Cell: (row) => {
        if (
          !row.original.po ||
          row.original.po.orderPaymentStatus == 'PAID' ||
          row.original.po.orderStatus.name == 'Trade - Declined' ||
          row.original.po.orderStatus.name == 'Cancelled'
        ) {
          return null;
        }
        return (
          <Button
            onClick={() => this.openEmailModal(row.original)}
            variant='contained'
            style={{
              marginRight: 15,
              padding: '0px 16px',
              minHeight: 30,
              background: '#34b4c9',
              color: 'white',
            }}
            disabled={this.state.submitting}>
            Pay
          </Button>
        );
      },
    },
  ];

  orderSubcolumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 100,
      Cell: (row) => (
        <ExternalLink
          href={
            'https://use1.brightpearlapp.com/patt-op.php?scode=product&action=new_product&pID=' +
            row.original.bpId
          }
          content={row.value}
        />
      ),
    },
    { Header: 'Brand', accessor: 'brand', width: 250 },
    { Header: 'Name', accessor: 'name', width: 250 },
    { Header: 'Notes', accessor: 'notes.intake', width: 250 },
    {
      Header: 'High',
      accessor: 'price.high',
      width: 80,
      style: {
        textAlign: 'right',
      },
    },
    {
      Header: 'Low',
      accessor: 'price.low',
      width: 80,
      style: {
        textAlign: 'right',
      },
    },
    {
      Header: 'Cost',
      accessor: 'price.cost',
      width: 80,
      style: {
        textAlign: 'right',
      },
    },
  ];

  boolCellStyle = {
    textAlign: 'center',
  };

  sharedColumns = {
    sku: {
      Header: 'SKU',
      accessor: 'sku',
      width: 100,
      Cell: (row) => (
        <ExternalLink
          href={
            'https://use1.brightpearlapp.com/patt-op.php?scode=product&action=new_product&pID=' +
            row.original.bpId
          }
          content={row.value}
        />
      ),
    },
    brand: { Header: 'Brand', accessor: 'brand', width: 200 },
    name: { Header: 'Name', accessor: 'name', width: 300 },
  }

  itemColumns = [
    {
      Header: 'Info',
      columns: [
        this.sharedColumns.sku,
        this.sharedColumns.brand,
        this.sharedColumns.name,
        { Header: 'Employee', accessor: 'order.submittedBy', width: 100 },
        {
          Header: 'Status',
          accessor: 'po.orderStatus.name',
          width: 150,
          Cell: (row) => {
            let colorMap = {
              'Invoice received': '#55FF55',
              'Trade - To Test': '#e774ec',
              'Trade - Tested': '#d161ff',
              'Trade - Checked In': '#f7e7fe',
              'Trade - Awaiting Paypal': '#e5b3ff',
            };
            let color = colorMap[row.value] ? colorMap[row.value] : '#fff';
            let val = row.value
              ? row.value.replace('Trade - ', '')
              : 'NOT FOUND';
            return (
              <span
                style={{
                  backgroundColor: color,
                  borderRadius: '3px',
                  padding: '2px 4px',
                }}>
                {val}
              </span>
            );
          },
        },
        {
          Header: 'Order',
          accessor: 'order.id',
          width: 85,
          Cell: (row) => (
            <a
              style={{
                backgroundColor: `#${row.value
                  .toString()
                  .split('')
                  .reverse()
                  .join('')}5e`,
                borderRadius: '3px',
                padding: '2px 4px',
              }}
              href={
                'https://use1.brightpearlapp.com/patt-op.php?scode=invoice&oID=' +
                row.value
              }>
              {row.value}
            </a>
          ),
        },
      ],
    },

    {
      Header: 'Status',
      columns: [
        {
          Header: 'Needs Test',
          accessor: 'needsTesting',
          style: this.boolCellStyle,
          width: 100,
          headerClassName: 'blue-header',
          className: 'lblue',
          Cell: (row) => this.boolCheck(row),
          filterMethod: this.boolFilterMethod,
          Filter: this.boolFilterDisplay,
        },
        {
          Header: 'Tested',
          accessor: 'tested',
          style: this.boolCellStyle,
          width: 100,
          headerClassName: 'blue-header',
          className: 'lblue',
          Cell: (row) => this.boolCheck(row),
          filterMethod: this.boolFilterMethod,
          Filter: this.boolFilterDisplay,
        },
        {
          Header: 'Needs Fix',
          accessor: 'needsRepair',
          style: this.boolCellStyle,
          width: 100,
          headerClassName: 'blue-header',
          className: 'lblue',
          Cell: (row) => this.boolCheck(row),
          filterMethod: this.boolFilterMethod,
          Filter: this.boolFilterDisplay,
        },
        {
          Header: 'Received',
          accessor: 'po.stockStatusCode',
          style: this.boolCellStyle,

          width: 100,
          filterMethod: this.boolFilterMethod,
          Filter: this.boolFilterDisplay,
          Cell: (row) => {
            if (row.value == 'POA') {
              return <LocalShippingIcon style={{ fontSize: 18 }} />;
            }
            return null;
          },
        },
        {
          Header: 'Photos',
          accessor: 'photos.hasPhotos',
          style: this.boolCellStyle,
          width: 100,
          filterMethod: this.boolFilterMethod,
          Filter: this.boolFilterDisplay,
          Cell: (row) => {
            if (row.value) {
              return <PhotoCameraIcon style={{ fontSize: 18 }} />;
            }
            return null;
          },
        },
        {
          Header: 'Edits',
          accessor: 'photos.hasEdits',
          style: this.boolCellStyle,
          width: 100,
          filterMethod: this.boolFilterMethod,
          Filter: this.boolFilterDisplay,
          Cell: (row) => {
            if (row.value) {
              return <PhotoIcon style={{ fontSize: 18 }} />;
            }
            return null;
          },
        },
        {
          Header: 'Listed',
          accessor: 'm2',
          style: this.boolCellStyle,
          width: 100,
          filterMethod: this.boolFilterMethod,
          Filter: this.boolFilterDisplay,
          Cell: (row) => {
            if (row.value != undefined) {
              return <CheckIcon style={{ fontSize: 18 }} />;
            }
            return null;
          },
        },
        {
          Header: 'Stage',
          accessor: 'stage',
          width: 100,
          Filter: ({ filter, onChange }) => (
            <select
              onChange={(event) => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}>
              <option value='all'>all</option>

              <option value='quote'>quote</option>
              <option value='testing'>testing</option>
              <option value='tested'>tested</option>
              <option value='repair'>repair</option>
              <option value='brad'>brad</option>
              <option value='photo'>photo</option>
              <option value='edit'>edit</option>
              <option value='listing'>listing</option>
              <option value='listed'>listed</option>
              <option value='ended'>ended</option>
              <option value='canceled'>canceled</option>
            </select>
          ),
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else {
              return row[filter.id] == filter.value;
            }
          },
        },
      ],
    },
  ];

  notesColumns = [

    this.sharedColumns.sku,
    this.sharedColumns.brand,
    this.sharedColumns.name,
    {
      Header: 'Intake',
      accessor: 'notes.intake',
      Cell: (row) => {
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {row.value}
          </Typography>)
      }
    },
    {
      Header: 'Photo',
      accessor: 'notes.photo',
      Cell: (row) => {
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {row.value}
          </Typography>)
      }
    },
    {
      Header: 'Testing',
      accessor: 'notes.testing',
      Cell: (row) => {
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {row.value}
          </Typography>)
      }
    },
    {
      Header: 'Brad',
      accessor: 'notes.brad',
      Cell: (row) => {
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {row.value}
          </Typography>)
      }
    },
    {
      Header: 'Bryce',
      accessor: 'notes.bryce',
      Cell: (row) => {
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {row.value}
          </Typography>)
      }
    },
  ]


render() {
  return (
    <div>
      {/* Tabs + Tooltips */}
      <AppBar position='static' style={{ boxShadow: 'none', marginTop: 40 }}>
        <Tabs value={this.state.tabs} onChange={this.tabber}>
          <Tab label='Orders' />
          <Tab label='Items' />
          <Tab label='Notes' />
          <Tab label='Stages' />
        </Tabs>
      </AppBar>
      {/* email check modal */}
      <Modal open={this.state.emailModal} onClose={this.handleClose}>
        <Paper
          style={{
            position: 'absolute',
            top: `25%`,
            left: `calc(50% - 300px)`,
            width: 600,
            outline: 0,
            padding: 25,
          }}>
          <h2>Submitting Order {this.state.row.id}</h2>
          <p>
            Current customer email:{' '}
            {this.state.row.customer &&
              this.state.row.customer.email.replace('+trades', '')}
          </p>
          <FormControl
            style={{ minWidth: 400, display: 'block', paddingBottom: 20 }}>
            <TextField
              name='paymentEmail'
              label='Alternate Payment Email'
              required={true}
              value={this.state.paymentEmail}
              onChange={this.handleEmailChange}
              style={{ minWidth: 400 }}
            />
          </FormControl>
          <Button
            onClick={() => this.payOrder('GC')}
            variant='contained'
            style={{
              marginRight: 15,
              padding: '0px 16px',
              minHeight: 30,
              background: '#eb5202',
              color: 'white',
            }}
            disabled={this.state.submitting}>
            Gift Card
          </Button>
          <Button
            onClick={() => this.payOrder('PP')}
            variant='contained'
            style={{
              marginRight: 15,
              padding: '0px 16px',
              minHeight: 30,
              background: '#003087',
              color: 'white',
            }}
            disabled={this.state.submitting}>
            Paypal
          </Button>
        </Paper>
      </Modal>

      {/* response modal */}
      <UsedModal
        open={this.state.responseModal}
        response={this.state.response}
        handleClose={this.handleClose}
      />

      {this.state.submitting && (
        <LinearProgress
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          }}
          color='secondary'
        />
      )}

      {/* orders table */}
      {this.state.tabs === 0 && (
        <ReactTable
          data={this.props.used.orders}
          columns={this.orderColumns}
          loading={this.state.loading}
          loadingText='Loading...'
          className='-striped -highlight'
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                alignItems: 'center',
                height: 45,
              },
            };
          }}
          filterable
          defaultFilterMethod={(filter, row) =>
            this.searchFilter(filter, row)
          }
          expanded={this.state.expanded}
          onExpandedChange={(expanded, index, event) => {
            this.setState({ expanded });
          }}
          SubComponent={(row) => {
            return (
              <ReactTable
                data={row.original.products}
                columns={this.orderSubcolumns}
                style={{ height: 300 }}
                className='-striped -highlight'
              />
            );
          }}
        />
      )}

      {/* items table */}

      {this.state.tabs === 1 && (
        <ReactTable
          data={this.props.used.products}
          filterable
          defaultFilterMethod={(filter, row) =>
            this.searchFilter(filter, row)
          }
          pageSize={100}
          columns={this.itemColumns}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
        />
      )}
      {this.state.tabs == 2 && (
        <ReactTable
          data={this.props.used.products}
          filterable
          multiSort={false}
          defaultFilterMethod={(filter, row) =>
            this.searchFilter(filter, row)
          }
          defaultSortMethod={(a, b, desc) =>
            this.sortMethod(a, b, desc)
          }
          pageSize={100}
          columns={this.notesColumns}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
        />
      )}
      {this.state.tabs === 3 && (
        <div style={{ padding: '10px 40px' }}>
          <h2>Used Product Stage Reference</h2>
          <h3>Testing</h3>
          <p>
            Hasn't been received, marked as needed testing. Should be on Doc's
            sheet
          </p>
          <h3>Tested</h3>
          <p>
            Fully tested but still awaiting testing on other items on the PO
          </p>
          <h3>Quote</h3>
          <p>Fully tested but unreceived. Awaiting customer price approval</p>
          <h3>Photo</h3>
          <p>In photo WH, has not been marked as photo'd on Andy's sheet</p>
          <h3>Edit</h3>
          <p>
            In photo WH with an entry on Andy's sheet but no edited photos on
            dropbox
          </p>
          <h3>Pricing</h3>
          <p>Fully edited but not approved</p>
          <h3>Listing</h3>
          <p>Approved but not listed on Magento</p>
          <h3>Listed</h3>
          <p>Listed (enabled or disabled) on Magento</p>
          <h3>Ended</h3>
          <p>Sold, donezo</p>
          <h3>Repair</h3>
          <p>In the With Doc, MFG Holding, Returns, or Warranty WH</p>
          <h3>Brad</h3>
          <p>In the With Brad WH</p>
          <h3>Canceled</h3>
          <p>PO is canceled or deleted</p>
        </div>
      )}
    </div>
  );
}
}

function mapStateToProps({ used }) {
  return { used };
}
const mapDispatchToProps = {
  applyUsedCredit,
  fetchUsedMasterReport,
  updateUsedProduct,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsedTable);
