import React from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

const TopBar = props => {
  //accepts an optional subHeading attribute
  return (
    <Toolbar style={{ padding: '10px 30px 15px', borderBottom: '1px solid #e5e5e5', flexWrap: 'wrap' }}>
      <Grid container>
        {props.children ? (
          MapRows(props)
        ) : (
          <Grid container spacing={2} alignItems="baseline">
            <Grid item xs={12} md={props.subHeading ? 6 : 2}>
              <Typography variant="h6">{props.pageName}</Typography>
              {props.subHeading ? <Typography variant="subtitle1">{props.subHeading}</Typography> : ''}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Toolbar>
  );
};

function MapRows(props) {
  return React.Children.map(props.children, (child, index) => {
    // First row prints page name in top left taking 2/12. 10 cols left
    return index === 0 && props.pageName ? (
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={2}>
            <Typography variant="h6">{props.pageName}</Typography>
          </Grid>
          <Grid item xs={10}>
            {child}
          </Grid>
        </Grid>
      </Grid>
    ) : (
      // Subsequent rows are full width
      <Grid item xs={12}>
        {child}
      </Grid>
    );
  });
}

export default TopBar;
