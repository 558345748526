import { 
  FETCH_BRANDTEXT,
  UPDATE_TEXT_ATTRIBUTES,
 } from '../actions/types';
import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_BRANDTEXT:
      draft.brand = action.payload;
      return;
    case UPDATE_TEXT_ATTRIBUTES:
      draft.response = action.payload;
      return;
  }
}, {});
