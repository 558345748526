import React, { useEffect, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ReactTable from 'react-table'


const BrandLocationTable = (props) => {

  const columns = [
    {
      Header: 'Location',
      accessor: 'location'
    },
    {
      Header: 'On Hand',
      accessor: 'onHand'
    }
  ]

  return (
    <>
      <Grid container spacing={2} style={{ padding: 15 }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            value={props.currentBrand}
            onChange={props.onChangeBrand}
            options={props.brands}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label='Brand' variant='outlined' />
            )}
            onKeyDown={(event) => event.stopPropagation()}
          />
        </Grid>
      </Grid>
      {(props.brandLocations.length > 0) && (
        <ReactTable
          data={props.brandLocations}
          columns={columns}
          defaultSorted={[{ id: 'onHand', desc: true }]}
        />
      )}
    </>
  )
}

export default BrandLocationTable
