import { 
  FETCH_BRANDIMAGES,
  FETCH_PHOTOWH,
  FETCH_IMAGEAUDIT,
  UPDATE_SUBOPTIMAL_IMAGES
 } from '../actions/types';
import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_BRANDIMAGES:
      draft.brand = action.payload;
      return;
    case FETCH_PHOTOWH:
      draft.photowh = action.payload;
      return;
    case FETCH_IMAGEAUDIT:
      draft.imageaudit = action.payload;
      return;
    case UPDATE_SUBOPTIMAL_IMAGES:
      draft.response = action.payload;
      return;
  }
}, {});
